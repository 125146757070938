// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Hiders                                                                   *
\* ------------------------------------------------------------------------ */

.hide-xxl {
    display: none !important;

    &.inline {
        display: none !important;
    }
}

.show-xxl {
    display: block !important;

    &.inline {
        display: inline-block !important;
    }
}
