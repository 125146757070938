// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * User Content                                                             *
\* ------------------------------------------------------------------------ */

.user-content h1,
.user-content h2 {
    font-size: 24px;
    font-size: remify(24);
}

.user-content h3,
.user-content h4,
.user-content h5,
.user-content h6 {
    font-size: 20px;
    font-size: remify(20);
}

.user-content menu,
.user-content ol,
.user-content p,
.user-content table,
.user-content ul {
    font-size: 16px;
    font-size: remify(16);
}

ul.two-column {
    overflow: hidden;
}

ul.two-column li {
    float: left;
    width: 50%;
}

.user-content table.blades {
    width: auto;

    & > tbody {
        width: auto;
        display: table-row-group;
    }
}

.user-content table {
	display: table;
    overflow: visible;
    padding: 0;

    &.stack {
        thead {
            display: table-header-group;
        }
        
        tbody {
            display: table-row-group;
        }
        
        tfoot {
            display: table-footer-group;
        }
        
        tr {
            display: table-row;
        }
        
        th,
        td {
            display: table-cell;
        }
        
        th:not(:first-child),
        td:not(:first-child) {
            padding-top: remify(12, 12);
        }
        
        th:not(:last-child),
        td:not(:last-child) {
            border-bottom-width: 1px;
            padding-bottom: remify(12, 12);
        }
    }
}

.user-content input,
.user-content label,
.user-content .select,
.user-content select,
.user-content textarea {
    font-size: 16px;
    font-size: remify(16);
}

.user-content button,
.user-content input[type=button],
.user-content input[type=reset],
.user-content input[type=submit],
.user-content .button {
    font-size: 18px;
    font-size: remify(18);
}
