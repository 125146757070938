// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Content                                                                  *
\* ------------------------------------------------------------------------ */

.content-wrapper {
    @include layer("content");

    background: $background_alt;
    font-size: 16px;
    font-size: remify(16);
    padding: 22px 25px;
    padding: remify(21.5 25);
    
    &:nth-of-type(odd) {
        background: $background;
    }
}

.content {
    @include clear;

    margin: 0 auto;
    max-width: $site-width + px;
    max-width: remify($site-width);
}

.content .sidebar {
    padding-bottom: remify(20, 16);
}

.single-product .post {
    display: flex;
    flex-direction: column;
}

.single-product .post .product-left {
    order: 2;
}

.single-product .post .product-right {
    order: 1;
}
