// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * User Content                                                             *
\* ------------------------------------------------------------------------ */

.user-content > *:last-child,
.user-content > .grid:last-child .grid-item *:last-child {
    margin-bottom: 0 !important;
}

.user-content h1,
.user-content h2,
.user-content h3,
.user-content h4,
.user-content h5,
.user-content h6 {
    color: $accent;
    font-family: $heading-font;
    text-transform: uppercase;
}

.user-content h1,
.user-content h2 {
    font-size: 15px;
    font-size: remify(15);
    margin: remify(0 0 4, 15);
}

.user-content h1 {
    margin: 0;
}

.user-content h2 {
    margin: remify(0 0 8, 15);
}

.user-content h3 {
    font-size: 14px;
    font-size: remify(14);
    margin: remify(0, 14);
}

.user-content h4 {
    color: $foreground;
    font-size: 14px;
    font-size: remify(14);
    font-weight: 400;
    margin: remify(0, 14);
    
    .single-product & {
        margin: remify(0 0 20, 14);
    }
}

.user-content h5 {
    font-size: 12px;
    font-size: remify(12);
    margin: remify(0, 12);
}

.user-content h6 {
    color: $foreground;
    font-size: 12px;
    font-size: remify(12);
    font-weight: 400;
    margin: 0;
}

.user-content menu,
.user-content ol,
.user-content p,
.user-content table,
.user-content ul {
    color: $foreground;
    font-family: $body-font;
    font-size: 12px;
    font-size: remify(12);
    line-height: remify(15, 12);
    margin: remify(0 0 15, 12);
}

.user-content menu,
.user-content ol,
.user-content ul {
    padding: remify(0 0 0 24, 16);

    menu,
    ol,
    ul {
		margin-bottom: 0;
    }
    
    li {
        margin: remify(0 0 3, 12);
    }
}

.content-wrapper:nth-of-type(even) .user-content table tr:not(:last-child) {
    & td,
    & th {
        border-bottom: 1px solid $foreground_alt;
    }
}

.user-content table.blades {
    width: auto;

    & > tbody {
        width: 750px;
        display: block;
    }
}

.header-wrapper + .content-wrapper .user-content table {
    width: auto;
}

.user-content table {
    display: block;
    overflow: auto;
    padding-bottom: 1px;
    padding-bottom: remify(1, 16);
    table-layout: fixed;
    width: 100%;
    // iOS fix
    -webkit-overflow-scrolling: touch;
    
    &.stack {
        overflow: hidden;
        width: auto;
        
        thead,
        tbody,
        tfoot,
        tr,
        th,
        td {
            display: block;
        }
        
        th:not(:first-child),
        td:not(:first-child) {
            padding-top: 0;
        }
        
        th:not(:last-child),
        td:not(:last-child) {
            border-bottom-width: 0;
        }
        
        th:not(:last-child) {
            padding-bottom: 0;
        }
    }
}

.user-content table th,
.user-content table td {
    border-bottom: 1px solid lighten($foreground, 67.4510);
    padding: remify(12 12 12 0, 12);
    vertical-align: top;

    &.rating img {
        margin: 0;
        padding: 0 3px;
        height: 15px;
    }
}

.user-content table *:last-child tr:last-child td,
.user-content table *:last-child tr:last-child th,
.user-content table > tr:last-child td,
.user-content table > tr:last-child th {
    border-bottom: 0;
}

.user-content table th,
.user-content table thead td {
    color: $accent;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
}

.user-content table tfoot td,
.user-content table tfoot th {
    font-style: italic;
}

.user-content table menu,
.user-content table ol,
.user-content table p,
.user-content table table,
.user-content table ul {
    margin: remify(0 0 20, 12);
    
    &:last-child {
        margin-bottom: 0 !important;
    }
}

.user-content a {
    color: $accent;
    text-decoration: none;
    transition: color 0.15s;

    &:hover {
        color: $accent_alt;
    }
}

.user-content pre {
    color: $foreground;
    font-family: monospace;
    font-size: 16px;
    font-size: remify(16);
}


.user-content input,
.user-content label,
.user-content .select,
.user-content select,
.user-content textarea {
    color: $foreground;
    font-family: $body-font;
    font-size: 12px;
    font-size: remify(12);
    line-height: remify(18, 12);
}

.user-content label {
	display: block;
    margin: remify(0 0 10, 16);

    &:hover {
        cursor: pointer;
    }
}

@include placeholder {
    color: $foreground;
    opacity: 1;
}

.user-content button,
.user-content input[type=button],
.user-content input[type=reset],
.user-content input[type=submit],
.user-content .button {
	background: $accent;
	border: 0;
    color: $background;
	display: inline-block;
    font-family: $body-font;
    font-size: 15px;
    font-size: remify(15);
    font-weight: 700;
    margin: remify(0 0 10, 15);
    padding: remify(11 20 10, 15);
    text-transform: uppercase;
	transition: background 0.15s, color 0.15s;

    &:hover {
        background: $accent_alt;
        color: $background;
    }
}

.user-content p .button {
    margin: 0;
}

.user-content input[type=checkbox],
.user-content input[type=radio] {
	left: -999em;
	position: absolute;

    & + label {
        position: relative;
        padding: remify(9 0 11 52, 16);

        &:before {
            background: $background;
            border: 1px solid $accent;
            border-width: remify(1);
            color: $foreground;
            content: "\0020";
            display: block;
            float: left;
            font-size: remify(25, 16);
            height: remify(44, 25);
            left: 0;
            line-height: remify(41, 25);
            position: absolute;
            text-align: center;
            top: 0;
            width: remify(44, 25);

            // webkit focus rings to emulate checkbox/radio
            .-webkit- &:active,
            .-webkit- &:focus {
                outline: -webkit-focus-ring-color auto 5px;
            }

            // trident focus rings to emulate checkbox/radio
            .-ms- &:active,
            .-ms- &:focus {
                outline: #808080 dashed 1px;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.user-content input[type=checkbox]:focus + label:before,
.user-content input[type=radio]:focus + label:before {
    outline: -webkit-focus-ring-color auto 5px;
}

.user-content input[type=checkbox][disabled] + label:before,
.user-content input[type=radio][disabled] + label:before {
    background: $background_alt;
}

.user-content input[type=checkbox]:checked + label:before {
    @include icon("fontawesome", "check");
}

.user-content input[type=radio] + label:before {
    border-radius: 50%;
}

.user-content input[type=radio]:checked + label:before {
    @include icon("fontawesome", "circle");
}

.user-content input[type=color],
.user-content input[type=date],
.user-content input[type=datetime],
.user-content input[type=datetime-local],
.user-content input[type=email],
.user-content input[type=month],
.user-content input[type=number],
.user-content input[type=password],
.user-content input[type=range],
.user-content input[type=search],
.user-content input[type=tel],
.user-content input[type=text],
.user-content input[type=time],
.user-content input[type=url],
.user-content input[type=week],
.user-content .select,
.user-content textarea {
	background: $background;
    border: 1px solid $accent;
    border-radius: remify(4, 12);
	box-sizing: border-box;
	display: block;
    margin: remify(0 0 10, 12);
	outline: none;
    overflow: hidden;
    padding: remify(8 12 8, 12);
	text-overflow: ellipsis;
	width: 100%;
}

.user-content input[type=color] {
    height: remify(36, 12);
    padding: 0;
}

.user-content .select {
    overflow: visible;
	padding: 0;
    position: relative;
}

.user-content .select:before {
    @include icon("fontawesome", "sort");

    color: $background;
    display: block;
    font-size: remify(24, 16);
    line-height: normal;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: remify(42, 24);
    z-index: 2;
}

.user-content .select:after {
    background: $accent;
    bottom: 0;
    content: "\0020";
    display: block;
    font-size: remify(24, 16);
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: remify(42, 24);
    z-index: 1;
}

.user-content .select select {
    background: none;
    border: 0;
    display: block;
    margin: 0;
    outline: none;
    padding: remify(9 54 11 12, 16);
    width: 100%;
    // gecko fix
    text-indent: 0.01px;
    text-overflow: "";
    -moz-appearance: none;
    // webkit fix
    -webkit-appearance: none;
    // trident fix
    &::-ms-expand {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }
}

.user-content textarea {
	max-width: 100%;
    min-height: remify(100, 16);
	min-width: 100%;
}

// fix for inconsistancies accross browsers

.user-content .select option {
    background: #FFF;
    color: #000;

    &:hover {
        background: #DDD;
    }
}

.user-content blockquote {
    border-left: 5px solid $accent;
    margin: 0 0 24px;
    margin-bottom: remify(24);
    padding: 0 0 0 24px;
    padding-left: remify(24);
}

.user-content hr {
    border: 0;
    border-bottom: 1px solid $accent;
    margin: 0 0 24px;
    margin-bottom: remify(24);
}

.user-content img {
	height: auto;
    max-width: 100%;
}

.user-content pre {
    background: $foreground_alt;
    border: 1px solid $foreground;
    color: $background;
    display: block;
    font-family: monospace;
    font-size: remify(12, 16);
    font-weight: 400;
    margin: remify(0 0 12, 12);
    padding: remify(10 12, 12);
    width: 100%;
}
