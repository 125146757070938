// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Menu Buton                                                               *
\* ------------------------------------------------------------------------ */

.menu-button {
    background: none;
    border: 1px solid darken(desaturate(adjust-hue($accent, -0.1066), 14.1600), 27.0588);
    border-radius: remify(2, 16);
    color: $accent;
    display: block;
    font-size: 16px;
    font-size: remify(16);
    height: remify(41, 16);
    margin: 0;
    padding: 0;
    position: relative;
    text-indent: -999em;
    transition: background-color 0.15s;
    width: remify(40, 16);

    &:hover {
        background: none;
        color: $accent;
    }
}

.menu-button:before,
.menu-button:after {
    background: currentColor;
    bottom: 0;
    content: "\0020";
    display: block;
    height: remify(3, 16);
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    transition: box-shadow 0.15s 0.15s, top 0.15s 0.15s, transform 0.15s;
    width: remify(20, 16);

    .navopen & {
        transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s;
    }
}

.menu-button:before {
    box-shadow: remify(0 6 0 0, 16) currentColor;
    top: remify(-12, 16);

    .navopen & {
        box-shadow: 0 0 0 0 transparent;
        transform: rotate(45deg);
        top: 0;
    }
}

.menu-button:after {
    top: remify(12, 16);

    .navopen & {
        transform: rotate(-45deg);
        top: 0;
    }
}
