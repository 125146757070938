// Styles written by YOURNAME @ YOURCOMPANY

/* ------------------------------------------------------------------------ *\
 * Swiper                                                                   *
\* ------------------------------------------------------------------------ */

// custom styles

.swiper-caption {
    right: 0;
    padding: remify(24 20 42, 16);
    position: absolute;
    top: 25%;
    transform: translateY(-50%);
    width: remify(380, 16);
}

.swiper-caption .hdg.hdg2 {
    font-size: remify(20, 16);
}

.swiper-caption .txt {
    font-size: remify(16, 16);
}

.swiper-caption .btn {
    bottom: auto;
    display: inline-block;
    font-size: remify(16, 16);;
    left: remify(20, 21.39);
    top: 100%;
    transform: translateY(-50%);
    width: auto;
}
