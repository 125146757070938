// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Hiders                                                                   *
\* ------------------------------------------------------------------------ */

.hide-xxs, .hide-xs, .hide-s, .hide-m, .hide-l, .hide-xl, .hide-xxl {
    display: block !important;

    &.inline {
        display: inline-block !important;
    }
}

.show-xxs, .show-xs, .show-s, .show-m, .show-l, .show-xl, .show-xxl {
    display: none !important;

    &.inline {
        display: none !important;
    }
}
