// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Mini Product                                                             *
\* ------------------------------------------------------------------------ */

.mini-product-image {
    margin: remify(0 0 12, 16);
    width: 100%;
}

.mini-product-image img {
    height: auto !important;
    width: 100%;
}

.mini-product .hdg.hdg1 {
    font-size: remify(18.235, 16);
    line-height: remify(15.195, 18.235);
    margin: 0;
}

.mini-product .hdg.hdg2 {
    font-weight: 400;
    line-height: remify(15.195, 15);
}

.mini-product .hdg.hdg2 .link {
    color: $foreground;
    
    &:hover {
        color: $foreground_alt;
    }
}
