// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Slideshow                                                                *
\* ------------------------------------------------------------------------ */

.slideshow {
    margin-bottom: 0;
}

.slideshow-wrapper .slideshow-menu .menu-list {
    display: flex;
}

.slideshow-wrapper .slideshow-menu .menu-item {
    display: flex;
    flex: 1 1 auto;
    float: none;
    margin: 0 !important;
    padding: 0 !important;
    width: 0;

    .modernizr-no-flexbox.modernizr-no-flexboxlegacy & {
        float: left;
        width: 25%;
    }
}

.slideshow-wrapper .slideshow-menu .menu-item .thumbnail-text {
    align-content: center;
    align-items: center;
    display: flex;
    padding-bottom: remify(16, 14);
    padding-top: remify(17, 14);
    width: 100%;
}

.slideshow-wrapper .slideshow-menu .menu-item.alt .thumbnail-text {
    &:after {
        border-bottom-width: remify(34, 14);
        border-right-width: remify(34, 14);
        border-top-width: remify(34, 14);
        margin-top: - remify(34, 14);
    }
}
