// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Mobile Navigation                                                        *
\* ------------------------------------------------------------------------ */

// wrapper

.is-navopen .mobile-nav-wrapper {
    right: 0;
    transition: none;
    top: 0;
}
