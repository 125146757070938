// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Footer                                                                   *
\* ------------------------------------------------------------------------ */

.footer-wrapper {
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;
}

.footer {
    @include clear;
}

.footer-left {
    background: $page_background;
    float: left;
    padding: remify(76 0 54, 16);
    position: relative;
    width: 50%;

    &:before {
        background: $page_background;
        bottom: 0;
        content: "\0020";
        display: block;
        position: absolute;
        right: 100%;
        top: 0;
        width: remify(2000, 16);
    }

    &:after {
        border-bottom: remify(75, 16) solid transparent;
        border-right: remify(75, 16) solid $accent;
        content: "\0020";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.footer-right {
    float: right;
    padding: remify(30 0 30 65, 16);
    width: 50%;
}

.footer .sitemap {
    @include clear;
}

.footer .sitemap .menu-list {
    color: $accent;
    float: left;
    font-family: $heading-font;
    font-size: remify(20, 16);
    font-weight: 700;
    line-height: remify(26.4, 20);
    margin: 0;
    padding: remify(0 20 0 0, 20);
    position: relative;
    text-transform: uppercase;
    width: (100% / 2);

    &:last-child {
        float: right;
    }
}

.footer .sitemap .menu-list.sub-menu,
.footer .sitemap .menu-item:hover .menu-list.sub-menu {
    color: $background;
    float: none;
    font-size: remify(12, 20);
    font-weight: 400;
    line-height: remify(20.8, 12);
    margin: remify(0 0 10, 12);
    max-height: none;
    padding: 0;
    width: 100%;
}

.footer .sitemap .menu-item a {
    color: $accent;
    padding: 0;
    text-decoration: none;
    transition: color 0.15s;

    &:hover {
        color: lighten($accent, 10);
    }
}

.footer .sitemap .menu-list.sub-menu .menu-item a {
    color: $background;

    &:hover {
        color: darken($background, 10);
    }
}

.footer .logo {
    margin: remify(0 0 16 -40, 16);
    width: remify(280, 16);
}

.footer .txt.txtp {
    font-size: remify(14, 16);
    line-height: remify(20.8, 14);
    text-align: left;
}
