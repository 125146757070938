// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Slideshow                                                                *
\* ------------------------------------------------------------------------ */

.slideshow-wrapper {
    @include layer("slideshow");

    background: $background_alt;
    font-size: 16px;
    font-size: remify(16);
    padding: 20px;
    padding: remify(20);
}

.slideshow {
    background: $background;
    margin: 0 auto 20px;
    margin: 0 auto remify(20);
    max-width: $site-width + px;
    max-width: remify($site-width);
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }
}

.slideshow-wrapper .slideshow-menu {
    @include clear;

    margin: 0 auto;
    max-width: $site-width + px;
    max-width: remify($site-width);
}

.slideshow-wrapper .slideshow-menu .menu-list {
    color: $background;
    font-family: $heading-font;
    font-size: remify(14, 16);
    font-weight: 700;
    line-height: remify(17.5, 14);
    margin: 0;
    text-align: left;
    text-transform: uppercase;
}

.slideshow-wrapper .slideshow-menu .menu-item {
    float: none;
}

.slideshow-wrapper .slideshow-menu .menu-item:not(:last-child) {
    margin: 0 0 1px;
}

.slideshow-wrapper .slideshow-menu .menu-item .thumbnail-text {
    background: $page_background;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: remify(40, 15) auto;
    color: $background;
    overflow: hidden;
    padding: remify(21 14 20 50, 15);
    text-decoration: none;
    transition: background 0.15s;

    &:hover {
        background-color: lighten($page_background, 10);
    }
}

.slideshow-wrapper .slideshow-menu .menu-item.active .thumbnail-text {
    background-color: $accent;
    cursor: pointer;
    position: relative;

    &:after {
        border-bottom: remify(30, 15) solid transparent;
        border-right: remify(30, 15) solid darken(desaturate(adjust-hue($accent, -0.1066), 14.5923), 8.6275);
        border-top: remify(30, 15) solid transparent;
        content: "\0020";
        display: block;
        height: 0;
        margin-top: - remify(30, 15);
        position: absolute;
        right: 0;
        top: 50%;
        transition: border-color 0.15s;
        width: 0;
    }
}
