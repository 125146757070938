// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Gallery                                                                  *
\* ------------------------------------------------------------------------ */

.gallery {
    @include clear;

    margin: remify(0 0 10, 16);
}

.gallery-images {
    background: $background;
    height: 0;
    margin: remify(0 0 10, 16);
    padding-bottom: 100%;
    position: relative;
    width: 100%;
}

.gallery-image {
    align-items: center;
    bottom: 0;
    display: flex;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.15s;
    z-index: 1;

    &.active {
        opacity: 1;
        z-index: 2;
    }
}

.gallery-image img {
    display: block;
    height: auto;
    max-width: none;
    width: 100%;
}

.gallery-thumbnails {
    @include clear;

    list-style: none;
    margin: 0;
    padding: 0;
}

.gallery-thumbnail {
    float: left;
    margin: remify(0 10 10 0, 16);
    width: 15%;
}

.gallery-thumbnail button {
    background: none;
    border: 1px solid $foreground;
    margin: 0;
    padding: 0;
    position: relative;
    transition: border-color 0.15s;
}

.gallery-thumbnail.-magic360 button {
    &::before,
    &::after {
        display: block;
        content: "\0020";
    }

    &::before {
        background: transparentize($dark, 0.5);
        height: 100%;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 2;
    }

    &::after {
        display: block;
        content: "360°";
        color: $light;
        font-family: $heading-font;
        font-size: remify(14, 16);
        font-weight: 700;
        left: 50%;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 3;
    }
}

.gallery-thumbnail.active button {
    border-color: $accent;
}

.gallery-thumbnail img {
    display: block;
    height: auto;
    width: 100%;
}
