// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Header                                                                   *
\* ------------------------------------------------------------------------ */

.header-wrapper {
    position: relative;
    width: 100%;
}

.header-left:before {
    background: $page_background_alt;
    bottom: 0;
    content: "\0020";
    display: block;
    right: 100%;
    position: absolute;
    top: 0;
    width: remify(2000, 16);
}

.header .logo {
    padding: remify(30.7 24, 16);
    width: remify(292, 16);
}
