// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Page                                                                     *
\* ------------------------------------------------------------------------ */

html,
body {
    width: 100%;
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

body {
    background: $background;
}

// IE snap fix

@-ms-viewport {
    width: device-width;
}
