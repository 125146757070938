// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Align                                                                    *
\* ------------------------------------------------------------------------ */

.aligncenter {
	margin: 0 auto remify(60, 16);
}

.alignleft {
    float: left;
	margin: remify(0 60 60 0, 16);
}

.alignright {
    float: right;
	margin: remify(0 0 60 60, 16);
}
