// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Page                                                                     *
\* ------------------------------------------------------------------------ */

html.is-navopen,
.is-navopen body {
    height: auto;
    width: auto;
}

.is-navopen .page-wrapper {
    box-shadow: none;
    left: auto;
}
