// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Menu List                                                                *
\* ------------------------------------------------------------------------ */

.l-vertical .menu-item.is-open > .menu-list.sub-menu,
.l-vertical .menu-item.is-open:hover > .menu-list.sub-menu {
    max-height: remify(5000, 16);
    transition: max-height 2.5s;
}

.menu-item.is-open > .menu-toggle:before {
    transform: rotate(-45deg);
}

.menu-item.is-open > .menu-toggle:after {
    transform: rotate(45deg);
}
