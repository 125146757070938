// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Menu List                                                                *
\* ------------------------------------------------------------------------ */

.menu-wrapper {
    @include clear;
}

.menu-list {
    @include clear;

    font-size: 1em;
    line-height: normal;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;

    .l-center & {
        float: right;
        right: 50%;
    }

    .l-vertical & {
        text-align: left;
    }
}

.menu-drop-down {
    float: none;
    right: auto;
    left: -999em;
    opacity: 0;
    position: absolute;
    text-align: left;
    top: -999em;
    transition: left 0s 0.15s, opacity 0.15s, top 0s 0.15s;
    width: remify($mobile-nav-width, 16);

    .menu-item:hover > & {
        left: 0;
        opacity: 1;
        top: 100%;
        transition: opacity 0.15s;
    }

    .menu-item-mega-menu & {
        display: flex;
        left: 0;
        right: 0;
        width: auto;
    }

    .l-vertical &,
    .l-vertical .menu-item:hover > & {
        left: auto;
        max-height: 0;
        opacity: 1;
        overflow: hidden;
        position: static;
        top: auto;
        transition: max-height 0.5s;
        width: auto;
    }
}

.menu-list.sub-menu {
    float: none;
    right: auto;
    left: auto;
    text-align: left;
    top: auto;
    width: auto;

    .menu-item-mega-menu & {
        flex: 1 1 50%;
    }

    .l-vertical & {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.25s;
    }

    .l-vertical .is-open > & {
        transition: max-height 2.5s;
        max-height: remify(5000, 16);
    }
}

.menu-item {
    float: left;
    position: relative;

    .l-center & {
        left: 50%;
    }

    .l-vertical & {
        float: none;
    }

    .social &:not(:last-child) {
        margin: remify(0 12 0 0, 16);
    }

    &.menu-item-mega-menu {
        position: static;
    }

    .menu-item-mega-menu & {
        display: flex;
        flex: 1 1 50%;
        flex-wrap: wrap;;
        left: 0;
        position: static;
        right: 0;
        width: auto;
    }

    .menu-item-mega-menu .sub-menu .sub-menu & {
        display: block;
    }

    .sub-menu & {
        float: none;
        left: auto;
        width: 100%;
    }

    &.-right > .menu-drop-down {
        left: auto;
        right: 0;
    }
}

.menu-item a {
    display: block;
    padding: remify(10 20, 16);

    .menu-item-mega-menu & {
        width: 100%;
    }
}

.social .menu-item a {
    color: $background;
    text-decoration: none;
    transition: background 0.15s;

    &:hover {
        color: $background;
    }

    &:before {
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        text-indent: 0;
        top: 0;
        width: 100%;
    }
}

.social .menu-item:not(.catalog) a {
    height: remify(32, 16);
    line-height: remify(32, 16);
    padding: 0;
    position: relative;
    text-align: center;
    text-indent: -999em;
    width: remify(32, 16);
}

.social .menu-item.catalog a {
    background: $primary;
    font-size: remify(11, 16);
    line-height: remify(11.96, 11);
    padding: remify(5 8 3 38, 11);
    text-align: left;
    text-transform: uppercase;

    &:hover {
        background: lighten($primary, 10);
    }

    &:before {
        @include icon("fontawesome", "book");

        font-size: remify(18, 11);
        left: remify(10, 16);
        line-height: normal;
        margin-top: - remify(8, 16);
        top: 50%;
    }
}

.social .menu-item.facebook a {
    background: #3B5998;

    &:hover {
        background: lighten(#3B5998, 10);
    }

    &:before {
        @include icon("fontawesome", "facebook");
    }
}

.social .menu-item.twitter a {
    background: #56A3D9;

    &:hover {
        background: lighten(#56A3D9, 10);
    }

    &:before {
        @include icon("fontawesome", "twitter");
    }
}

.social .menu-item.youtube a {
    background: #BF221F;

    &:hover {
        background: lighten(#BF221F, 10);
    }

    &:before {
        @include icon("fontawesome", "youtube");
    }
}

.menu-toggle {
    background: none;
    color: $accent;
    height: 2.6875em;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    text-indent: -999em;
    top: 0;
    width: 2.6875em;

    &:hover {
        background: none;
        color: $accent;
    }

    &:before,
    &:after {
        background: currentColor;
        content: "\0020";
        display: block;
        height: remify(4, 16);
        position: absolute;
        top: remify(20, 16);
        transition: transform 0.15s;
        width: remify(10, 16);
    }

    &:before {
        left: remify(14, 16);
        transform: rotate(45deg);
    }

    &:after {
        right: remify(14, 16);
        transform: rotate(-45deg);
    }
}
