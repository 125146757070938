// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Header                                                                   *
\* ------------------------------------------------------------------------ */

.header .header-left {
    &:after {
        border-bottom: remify(87, 16) solid transparent;
        border-right: remify(87, 16) solid $page_background;
        border-top: remify(87, 16) solid transparent;
        margin-top: - remify(87, 16);
    }
}

.header .header-right {
    width: remify(646, 16);
}

.header .logo {
    padding: remify(45.5 24, 16);
    width: remify(227, 16);
}

.header .hdg.hdg4 {
    margin: remify(4 24 3 0, 20);
}

.header .search-form {
    font-size: remify(16, 16);
    margin: remify(0 8 0 6, 16);
}

.header .menu-wrapper.social {
    padding: remify(14 26 14 30, 16);
}

.header .menu-wrapper.secondary {
    padding: remify(0 18 0 15, 16);
}

.header .menu-wrapper.primary {
    padding: remify(0 2 0 0, 16);
}

.header .menu-wrapper.secondary .menu-list {
    font-size: remify(16, 16);
}

.header .menu-wrapper.primary .menu-list {
    font-size: remify(20, 16);
}

.header .menu-wrapper.social .menu-item:not(:last-child) {
    margin: remify(0 8 0 0, 16);
}

.header .menu-wrapper.secondary .menu-item a {
    padding: remify(13 15 12, 16);
}

.header .menu-wrapper.primary .menu-item a {
    padding: remify(22 29, 20);
}
