// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Functions                                                                *
\* ------------------------------------------------------------------------ */

// Generate black rgba value with specified opacity

@function black($opacity) {
    @return rgba(0,0,0,$opacity)
}

// Generate white rgba value with specified opacity

@function white($opacity) {
    @return rgba(255,255,255,$opacity)
}

// Remove the unit of a length

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    @return $number;
}

// Remify

@function remify($sizes: ($size1, $size2, $size3, $size4), $base: null, $unit: null) {
    $value-rem: null;
    @if $base == null {
        $base: 16;
        @if $unit == null {
            $unit: rem;
        }
    } @else {
        @if $unit == null {
            $unit: em;
        }
    }
    @each $size in $sizes {
        @if $value-rem == null {
            $value-rem: (strip-unit($size) / strip-unit($base)) + $unit;
        } @else {
            $value-rem: $value-rem + " " + ((strip-unit($size) / strip-unit($base)) + $unit);
        }
    }
    @return $value-rem;
}
