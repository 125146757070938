// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Header                                                                   *
\* ------------------------------------------------------------------------ */

.header {
    position: relative;
}

.header .header-left {
    &:after {
        border-bottom: remify(73, 16) solid transparent;
        border-right: remify(73, 16) solid $page_background;
        border-top: remify(73, 16) solid transparent;
        margin-top: - remify(73, 16);
    }
}

.header .header-right {
    position: static;
    width: remify(475, 16);
}

.header .logo {
    padding: remify(34 24, 16);
    width: remify(220, 16);
}

.header .hdg.hdg4 {
    color: $background;
    float: left;
    font-size: remify(20, 16);
    margin: remify(4 26 3 0, 20);
}

.header .hdg.hdg4 a {
    color: $background;
    text-decoration: none;
    transition: color 0.15s;

    &:hover {
        color: $background_alt;
    }
}

.header .search-form {
    @include flex-fix;

    flex: 1 1 auto;
    float: left;
    font-size: remify(12, 16);
    margin: remify(0 11, 16);
    width: remify(220, 16);

    .flexbox &,
    .flexboxlegacy & {
        width: 0;
    }
}

.header .menu-wrapper.social {
    padding: remify(14 26 14 15, 16);
}

.header .menu-wrapper.secondary {
    display: flex;
    padding: remify(0 18 0 6, 16);
}

.header .menu-wrapper.primary {
    padding: remify(0 12 0 0, 16);
    position: static;
    width: 100%;
}

.header .menu-wrapper.social .menu-list {
    float: left;
}

.header .menu-wrapper.secondary .menu-list {
    @include flex-fix;

    color: $accent;
    flex: none;
    float: left;
    font-family: $body-font;
    font-size: remify(13, 16);
    font-weight: 400;
}

.header .menu-wrapper.primary .menu-list {
    color: $accent;
    font-family: $body-font;
    font-size: remify(16.25, 16);
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;

    &:not(.sub-menu) {
        position: static;
    }
}

.header .menu-wrapper.primary .menu-list.sub-menu {
    background: $page-background;
    font-size: remify(26, 20);
    padding: remify(30 0, 26);
    text-align: left;
}

.header .menu-wrapper.primary .menu-list.sub-menu .menu-list.sub-menu {
    background: none;
    font-size: remify(14, 26);
    font-weight: 400;
    line-height: remify(20, 14);
    padding: 0;
    width: auto;
}

.header .menu-wrapper.primary .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu {
    font-size: remify(14, 14);
    line-height: remify(20, 14);
    text-transform: none;
}

.header .menu-wrapper.primary .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu {
    padding-left: remify(10, 14);
}

.header .menu-wrapper.social .menu-item:not(:last-child) {
    margin: remify(0 12 0 0, 16);
}

.header .menu-wrapper.secondary .menu-item a {
    color: $accent;
    padding: remify(12 11 11, 16);
    text-decoration: none;
    transition: color 0.15s;

    &:hover {
        color: $background_alt;
    }
}

.header .menu-wrapper.primary .menu-item a {
    color: $accent;
    padding: remify(22 18, 20);
    text-decoration: none;
    transition: color 0.15s;

    &:hover {
        color: $background_alt;
    }
}

.header .menu-wrapper.primary .menu-list.sub-menu .menu-item a {
    padding: remify(2 28, 26);
}

.header .menu-wrapper.primary .menu-list.sub-menu .menu-list.sub-menu .menu-item a {
    padding: remify(0 28, 14);
}

.header .menu-wrapper.primary .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu .menu-item a {
    color: $background_alt;

    &:hover {
        color: $accent;
    }

    &:before {
        content: "- ";
    }
}
