// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Grid                                                                     *
\* ------------------------------------------------------------------------ */

// full

.grid-item.full-l {
    width: 100%;
}

// ones

.grid-item.one-half-l {
    width: (100% / 2);
}

.grid-item.one-third-l {
    width: (100% / 3);
}

.grid-item.one-fourth-l {
    width: (100% / 4);
}

.grid-item.one-fifth-l {
    width: (100% / 5);
}

.grid-item.one-sixth-l {
    width: (100% / 6);
}

.grid-item.one-seventh-l {
    width: (100% / 7);
}

.grid-item.one-eighth-l {
    width: (100% / 8);
}

.grid-item.one-ninth-l {
    width: (100% / 9);
}

.grid-item.one-tenth-l {
    width: (100% / 10);
}

// twos

.grid-item.two-half-l,
.grid-item.two-halfs-l,
.grid-item.two-halves-l {
    width: (100% / 3) * 2;
}

.grid-item.two-third-l,
.grid-item.two-thirds-l {
    width: (100% / 3) * 2;
}

.grid-item.two-fourth-l,
.grid-item.two-fourths-l {
    width: (100% / 4) * 2;
}

.grid-item.two-fifth-l,
.grid-item.two-fifths-l {
    width: (100% / 5) * 2;
}

.grid-item.two-sixth-l,
.grid-item.two-sixths-l {
    width: (100% / 6) * 2;
}

.grid-item.two-seventh-l,
.grid-item.two-sevenths-l {
    width: (100% / 7) * 2;
}

.grid-item.two-eighth-l,
.grid-item.two-eighths-l {
    width: (100% / 8) * 2;
}

.grid-item.two-ninth-l,
.grid-item.two-ninths-l {
    width: (100% / 9) * 2;
}

.grid-item.two-tenth-l,
.grid-item.two-tenths-l {
    width: (100% / 10) * 2;
}

// threes

.grid-item.three-third-l,
.grid-item.three-thirds-l {
    width: (100% / 3) * 3;
}

.grid-item.three-fourth-l,
.grid-item.three-fourths-l {
    width: (100% / 4) * 3;
}

.grid-item.three-fifth-l,
.grid-item.three-fifths-l {
    width: (100% / 5) * 3;
}

.grid-item.three-sixth-l,
.grid-item.three-sixths-l {
    width: (100% / 6) * 3;
}

.grid-item.three-seventh-l,
.grid-item.three-sevenths-l {
    width: (100% / 7) * 3;
}

.grid-item.three-eighth-l,
.grid-item.three-eighths-l {
    width: (100% / 8) * 3;
}

.grid-item.three-ninth-l,
.grid-item.three-ninths-l {
    width: (100% / 9) * 3;
}

.grid-item.three-tenth-l,
.grid-item.three-tenths-l {
    width: (100% / 10) * 3;
}

// fours

.grid-item.four-fourth-l,
.grid-item.four-fourths-l {
    width: (100% / 4) * 4;
}

.grid-item.four-fifth-l,
.grid-item.four-fifths-l {
    width: (100% / 5) * 4;
}

.grid-item.four-sixth-l,
.grid-item.four-sixths-l {
    width: (100% / 6) * 4;
}

.grid-item.four-seventh-l,
.grid-item.four-sevenths-l {
    width: (100% / 7) * 4;
}

.grid-item.four-eighth-l,
.grid-item.four-eighths-l {
    width: (100% / 8) * 4;
}

.grid-item.four-ninth-l,
.grid-item.four-ninths-l {
    width: (100% / 9) * 4;
}

.grid-item.four-tenth-l,
.grid-item.four-tenths-l {
    width: (100% / 10) * 4;
}

// fives

.grid-item.five-fifth-l,
.grid-item.five-fifths-l {
    width: (100% / 5) * 5;
}

.grid-item.five-sixth-l,
.grid-item.five-sixths-l {
    width: (100% / 6) * 5;
}

.grid-item.five-seventh-l,
.grid-item.five-sevenths-l {
    width: (100% / 7) * 5;
}

.grid-item.five-eighth-l,
.grid-item.five-eighths-l {
    width: (100% / 8) * 5;
}

.grid-item.five-ninth-l,
.grid-item.five-ninths-l {
    width: (100% / 9) * 5;
}

.grid-item.five-tenth-l,
.grid-item.five-tenths-l {
    width: (100% / 10) * 5;
}

// sixes

.grid-item.six-sixth-l,
.grid-item.six-sixths-l {
    width: (100% / 6) * 6;
}

.grid-item.six-seventh-l,
.grid-item.six-sevenths-l {
    width: (100% / 7) * 6;
}

.grid-item.six-eighth-l,
.grid-item.six-eighths-l {
    width: (100% / 8) * 6;
}

.grid-item.six-ninth-l,
.grid-item.six-ninths-l {
    width: (100% / 9) * 6;
}

.grid-item.six-tenth-l,
.grid-item.six-tenths-l {
    width: (100% / 10) * 6;
}

// sevens

.grid-item.seven-seventh-l,
.grid-item.seven-sevenths-l {
    width: (100% / 7) * 7;
}

.grid-item.seven-eighth-l,
.grid-item.seven-eighths-l {
    width: (100% / 8) * 7;
}

.grid-item.seven-ninth-l,
.grid-item.seven-ninths-l {
    width: (100% / 9) * 7;
}

.grid-item.seven-tenth-l,
.grid-item.seven-tenths-l {
    width: (100% / 10) * 7;
}

// eights

.grid-item.eight-eighth-l,
.grid-item.eight-eighths-l {
    width: (100% / 8) * 8;
}

.grid-item.eight-ninth-l,
.grid-item.eight-ninths-l {
    width: (100% / 9) * 8;
}

.grid-item.eight-tenth-l,
.grid-item.eight-tenths-l {
    width: (100% / 10) * 8;
}

// nines

.grid-item.nine-ninth-l,
.grid-item.nine-ninths-l {
    width: (100% / 9) * 9;
}

.grid-item.nine-tenth-l,
.grid-item.nine-tenths-l {
    width: (100% / 10) * 9;
}

// tens

.grid-item.ten-tenth-l,
.grid-item.ten-tenths-l {
    width: (100% / 10) * 10;
}
