// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Content                                                                  *
\* ------------------------------------------------------------------------ */

.content-wrapper:nth-child(2) {
    padding-top: 80px;
    padding-top: remify(80);
}

.content {
    position: relative;
}

.content .breadcrumb {
    bottom: 100%;
    filter: alpha(opacity=45);
    left: 0;
    opacity: 0.45;
    position: absolute;
    right: 0;
}

.content .breadcrumb p {
    border-bottom: 1px solid lighten($foreground, 54.9020);
    color: $foreground;
    font-family: $body-font;
    font-size: remify(13, 16);
    font-weight: 400;
    line-height: normal;
    margin: remify(0 0 32, 13);
    text-transform: uppercase;
}

.content .breadcrumb a,
.content .breadcrumb .breadcrumb_last {
    color: $foreground;
    display: inline-block;
    text-decoration: none;
    padding: remify(14 0, 13);
    transition: color 0.15s;
    
    &:hover {
        color: darken($foreground, 10);
    }
}


.single-product .post {
    @include clear;
    
    display: block;
}

.single-product .post .product-left {
    float: left;
    width: 61.25%;
}

.single-product .post .product-right {
    float: right;
    padding-left: $grid-gutter + px;
    padding-left: remify($grid-gutter);
    width: 38.75%;
}

