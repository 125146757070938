// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Footer                                                                   *
\* ------------------------------------------------------------------------ */

.footer-left {
    width: 70%;
}

.footer-right {
    width: 30%;
}

.footer .logo {
    width: remify(250, 16);
}
