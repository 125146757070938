// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Footer                                                                   *
\* ------------------------------------------------------------------------ */

.footer-left:after {
    border-bottom-width: remify(150, 16);
    border-right-width: remify(150, 16);
}

.footer .sitemap > .menu-list {
    @include clear;

    width: (100% / 3) * 2;

    &:last-child {
        float: left;
        width: (100% / 3);
    }
}

.footer .sitemap > .menu-list:not(:last-child) > .menu-item {
    float: left;
    width: (100% / 2);
}

.footer .logo {
    width: remify(280, 16);
}
