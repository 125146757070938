// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Header                                                                   *
\* ------------------------------------------------------------------------ */

.header-wrapper {
    position: relative;
    width: 100%;
}

.header .header-left {
    &:after {
        border-bottom: remify(106, 16) solid transparent;
        border-right: remify(106, 16) solid $page_background;
        border-top: remify(106, 16) solid transparent;
        margin-top: - remify(106, 16);
    }
}

.header .header-right {
    width: remify(620, 16);

    .modernizr-no-flexbox.modernizr-no-flexboxlegacy & {
        height: remify(215, 16);
    }
}

.header .logo {
    padding: remify(24.5 0, 16);
    width: remify(350, 16);
}

.header .hdg.hdg4 {
    margin: remify(4 24 3 0, 20);
}

.header .search-form {
    margin: remify(0 2 0 7, 16);
}

.header .menu-wrapper.social {
    padding: remify(32 0 16 28, 16);
}

.header .menu-wrapper.secondary {
    display: flex;
    padding: remify(0 0 0 13, 16);
}

.header .menu-wrapper.primary {
    display: flex;
    padding: 0;
}

.header .menu-wrapper.social .menu-item:not(:last-child) {
    margin: remify(0 6 0 0, 16);
}

.header .menu-wrapper.secondary .menu-item a {
    padding: remify(14 15 10, 16);
}

.header .menu-wrapper.primary .menu-item a {
    padding: remify(18.5 28, 20);
}

.header .menu-wrapper.primary .menu-item:last-child a {
    padding-right: 0;
}
