// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Footer                                                                   *
\* ------------------------------------------------------------------------ */

.footer-wrapper {
    @include layer("footer");

    background: $page_background_alt;
    font-size: 16px;
    font-size: remify(16);
    padding: 30px 20px;
    padding: remify(30 20);
}

.footer {
    @include clear;

    margin: 0 auto;
    max-width: $site-width + px;
    max-width: remify($site-width);
}

.footer .logo {
    margin: 0 auto remify(26, 16);
    width: remify(180, 16);
}

.footer .txt.txtp {
    color: $background;
    font-size: remify(12, 16);
    line-height: remify(15, 12);
    margin: remify(0 0 14, 12);
    text-align: center;

    &:last-child {
        margin-bottom: 0;
    }
}

.footer .txt.txtp a {
    color: $background;
    text-decoration: none;
    transition: color 0.15s;

    &:hover {
        color: background_alt;
    }
}
