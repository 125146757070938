// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Mobile Navigation                                                        *
\* ------------------------------------------------------------------------ */

// wrapper

.mobile-nav-wrapper {
    background: $page_background;
    bottom: 0;
    font-size: 16px;
    font-size: remify(16);
    overflow: auto;
    position: absolute;
    right: 999em;
    top: -999em;;
    transition: right 0s 0.15s, top 0s 0.15s;
    width: $mobile-nav-width + px;
    width: remify($mobile-nav-width);
    z-index: 1;
}

.mobile-nav .search-form {
    border-radius: remify(4, 16);
    margin: remify(14, 16);
}

.mobile-nav .hdg.hdg4 {
    color: $background;
    font-size: remify(20, 16);
    margin: remify(0 0 14, 20);
    text-align: center;
}

.mobile-nav .hdg.hdg4 a {
    color: $background;
    text-decoration: none;
    transition: color 0.15s;

    &:hover {
        color: $background_alt;
    }
}

.mobile-nav .social {
    padding: remify(0 14 10, 16);
}

.mobile-nav .menu-list {
    font-family: $body-font;
}

.mobile-nav .l-vertical .menu-list {
    text-align: right;
}

.mobile-nav .primary .menu-list {
    font-size: remify(20, 16);
    font-weight: 700;
    text-transform: uppercase;
}

.mobile-nav .primary .menu-list.sub-menu {
    background: lighten($page_background, 10);
    font-size: remify(18, 20);
}

.mobile-nav .primary .menu-list.sub-menu .menu-list.sub-menu {
    font-size: remify(14, 18);
    font-weight: 400;
    line-height: remify(20, 14);
}

.mobile-nav .primary .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu {
    font-size: remify(14, 14);
    line-height: remify(20, 14);
    text-transform: none;
}

.mobile-nav .primary .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu {
    padding-left: remify(10, 14);
}

.mobile-nav .secondary .menu-list {
    font-size: remify(16, 16);
}

.mobile-nav .social .menu-list {
    font-size: remify(14, 16);
}

.mobile-nav .social .menu-item:not(:last-child) {
    margin: remify(0 6.4 0 0, 16);
}

.mobile-nav .menu-item a {
    color: $accent;
    padding: remify(8 14, 20);
    text-decoration: none;

    &:hover {
        color: $accent_alt;
    }
}

.mobile-nav .menu-list.sub-menu .menu-item a {
    padding: remify(8 14, 18);
}

.mobile-nav .menu-list.sub-menu .menu-list.sub-menu .menu-item a {
    padding: remify(8 14, 14);
}

.mobile-nav .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu .menu-item a {
    color: $background_alt;

    &:hover {
        color: $accent;
    }

    &:after {
        content: " -";
    }
}

.mobile-nav .menu-toggle {
    border: 0;
    font-size: remify(15, 20);
    left: 0;
    right: auto;
}
