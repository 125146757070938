// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Meta                                                                     *
\* ------------------------------------------------------------------------ */

.meta-list,
.user-content ul.meta-list {
    font-family: $body-font;
    font-size: remify(12, 16);
    font-weight: 700;
	list-style: none;
	margin: remify(0 0 10, 12);
	padding: 0;
	text-align: left;
    text-transform: uppercase;
}

.meta-list.l-center {
    float: right;
    position: relative;
    right: 50%;
}

.meta-list li {
    display: inline-block;
    padding: remify(0 12 0 24, 16);
    position: relative;
}

.meta-list li a {
    color: $accent;
    text-decoration: none;
    transition: color 0.15s;

    &:hover {
        color: $accent_alt;
    }
}

.meta-list.l-center li {
    float: left;
    left: 50%;
    position: relative;
}

.meta-list li:before {
    font-weight: 400;
    left: 0;
    position: absolute;
    top: 0;
}

.meta-list li.comments:before {
    @include icon("fontawesome", "comment");
}

.meta-list li.categories:before {
    @include icon("fontawesome", "folder");
}

.meta-list li.tags:before {
    @include icon("fontawesome", "tag");
}

.meta-list li.time:before {
    @include icon("fontawesome", "clock-o");
}

.meta-list li.url:before {
    @include icon("fontawesome", "globe");
}
