// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Page                                                                     *
\* ------------------------------------------------------------------------ */

html.is-navopen,
.is-navopen body {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.is-navopen .page-wrapper {
    box-shadow: remify(0 0 10, 16) black(0.5);
    height: 100%;
    left: - $mobile-nav-width + px;
    left: - remify($mobile-nav-width);
    overflow: hidden;
    width: 100%;
}
