// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Content                                                                  *
\* ------------------------------------------------------------------------ */

.content-wrapper {
    padding-bottom: 60px;
    padding-bottom: remify(60);
    padding-top: 60px;
    padding-top: remify(60);
}
