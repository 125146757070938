// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Mini Product                                                             *
\* ------------------------------------------------------------------------ */

.mini-product .hdg.hdg1 {
    font-size: remify(24, 16);
}

.mini-product .hdg.hdg2 {
    font-size: remify(20, 16);
}
