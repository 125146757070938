// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Text Elements                                                            *
\* ------------------------------------------------------------------------ */

::selection {
	background: $accent_alt;
	color: $background;
}

.hdg.hdg1,
.hdg.hdg2 {
    font-size: 24px;
    font-size: remify(24);
}

.hdg.hdg3,
.hdg.hdg4,
.hdg.hdg5,
.hdg.hdg6 {
    font-size: 20px;
    font-size: remify(20);
}

.txt {
    font-size: 16px;
    font-size: remify(16);
}
