// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Generic Form Elements                                                    *
\* ------------------------------------------------------------------------ */

.btn {
    background: $accent;
    color: $background;
    display: inline-block;
    font-family: $heading-font;
    font-size: 15px;
    font-size: remify(15);
    font-weight: 700;
    padding: remify(9 16 8, 15);
	pointer-events: all; // for IE on slideshow
    text-decoration: none;
    text-transform: uppercase;
    transition: background 0.15s;

    &:hover {
        background: $accent_alt;
    }
}
