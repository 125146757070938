// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Align                                                                    *
\* ------------------------------------------------------------------------ */

.aligncenter {
    display: block;
	margin-bottom: remify(30, 16);
}

.alignleft {
    display: block;
    float: none;
    margin: 0 auto remify(30, 16);
}

.alignright {
    display: block;
    float: none;
    margin: 0 auto remify(30, 16);
}
