// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Page                                                                     *
\* ------------------------------------------------------------------------ */

.page-wrapper {
    background: $background;
    font-size: 16px;
    font-size: remify(16);
    left: 0;
    min-height: 100%;
    position: relative;
    transition: box-shadow 0.15s, left 0.15s;
    width: 100%;
    z-index: 2;
}
