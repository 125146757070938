// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Search Form                                                              *
\* ------------------------------------------------------------------------ */

.search-form {
    background: $background;
    border-radius: remify(12, 16);
    align-items: center;
	display: flex;
    
    .modernizr-no-flexbox.modernizr-no-flexboxlegacy & {
        position: relative;
        padding-right: remify(45, 16);
    }
}

.search-label {
    left: -999em;
    position: absolute;
    top: -999em;
}

.search-input {
    background: none;
    border: 0;
    flex: 1 1 auto;
    font-family: $body-font;
    font-size: remify(14, 16);
    font-weight: 300;
    line-height: normal;
    margin: 0;
    padding: remify(14 0 14 16, 14);
    text-overflow: ellipsis;
    width: 0;
    
    .modernizr-no-flexbox.modernizr-no-flexboxlegacy & {
        width: 100%;
    }
}

.search-submit {
    background: none;
    border: 0;
    color: $accent;
    display: block;
    flex: none;
    font-size: remify(16, 16);
    height: remify(45, 16);
    line-height: remify(45, 16);
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    text-indent: -999em;
    width: remify(45, 16);

    &:before {
        @include icon("fontawesome", "search");

        bottom: 0;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        text-indent: 0;
        top: 0;
        width: 100%;
    }
    
    .modernizr-no-flexbox.modernizr-no-flexboxlegacy & {
        position: absolute;
        right: 0;
        top: 0;
    }
}
