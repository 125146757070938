// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Grid                                                                     *
\* ------------------------------------------------------------------------ */

// full

.grid-item.full-s {
    width: 100%;
}

// ones

.grid-item.one-half-s {
    width: (99.42% / 2);
}

.grid-item.one-third-s {
    width: (100% / 3);
}

.grid-item.one-fourth-s {
    width: (100% / 4);
}

.grid-item.one-fifth-s {
    width: (100% / 5);
}

.grid-item.one-sixth-s {
    width: (100% / 6);
}

.grid-item.one-seventh-s {
    width: (100% / 7);
}

.grid-item.one-eighth-s {
    width: (100% / 8);
}

.grid-item.one-ninth-s {
    width: (100% / 9);
}

.grid-item.one-tenth-s {
    width: (100% / 10);
}

// twos

.grid-item.two-half-s,
.grid-item.two-halfs-s,
.grid-item.two-halves-s {
    width: (100% / 3) * 2;
}

.grid-item.two-third-s,
.grid-item.two-thirds-s {
    width: (100% / 3) * 2;
}

.grid-item.two-fourth-s,
.grid-item.two-fourths-s {
    width: (100% / 4) * 2;
}

.grid-item.two-fifth-s,
.grid-item.two-fifths-s {
    width: (100% / 5) * 2;
}

.grid-item.two-sixth-s,
.grid-item.two-sixths-s {
    width: (100% / 6) * 2;
}

.grid-item.two-seventh-s,
.grid-item.two-sevenths-s {
    width: (100% / 7) * 2;
}

.grid-item.two-eighth-s,
.grid-item.two-eighths-s {
    width: (100% / 8) * 2;
}

.grid-item.two-ninth-s,
.grid-item.two-ninths-s {
    width: (100% / 9) * 2;
}

.grid-item.two-tenth-s,
.grid-item.two-tenths-s {
    width: (100% / 10) * 2;
}

// threes

.grid-item.three-third-s,
.grid-item.three-thirds-s {
    width: (100% / 3) * 3;
}

.grid-item.three-fourth-s,
.grid-item.three-fourths-s {
    width: (100% / 4) * 3;
}

.grid-item.three-fifth-s,
.grid-item.three-fifths-s {
    width: (100% / 5) * 3;
}

.grid-item.three-sixth-s,
.grid-item.three-sixths-s {
    width: (100% / 6) * 3;
}

.grid-item.three-seventh-s,
.grid-item.three-sevenths-s {
    width: (100% / 7) * 3;
}

.grid-item.three-eighth-s,
.grid-item.three-eighths-s {
    width: (100% / 8) * 3;
}

.grid-item.three-ninth-s,
.grid-item.three-ninths-s {
    width: (100% / 9) * 3;
}

.grid-item.three-tenth-s,
.grid-item.three-tenths-s {
    width: (100% / 10) * 3;
}

// fours

.grid-item.four-fourth-s,
.grid-item.four-fourths-s {
    width: (100% / 4) * 4;
}

.grid-item.four-fifth-s,
.grid-item.four-fifths-s {
    width: (100% / 5) * 4;
}

.grid-item.four-sixth-s,
.grid-item.four-sixths-s {
    width: (100% / 6) * 4;
}

.grid-item.four-seventh-s,
.grid-item.four-sevenths-s {
    width: (100% / 7) * 4;
}

.grid-item.four-eighth-s,
.grid-item.four-eighths-s {
    width: (100% / 8) * 4;
}

.grid-item.four-ninth-s,
.grid-item.four-ninths-s {
    width: (100% / 9) * 4;
}

.grid-item.four-tenth-s,
.grid-item.four-tenths-s {
    width: (100% / 10) * 4;
}

// fives

.grid-item.five-fifth-s,
.grid-item.five-fifths-s {
    width: (100% / 5) * 5;
}

.grid-item.five-sixth-s,
.grid-item.five-sixths-s {
    width: (100% / 6) * 5;
}

.grid-item.five-seventh-s,
.grid-item.five-sevenths-s {
    width: (100% / 7) * 5;
}

.grid-item.five-eighth-s,
.grid-item.five-eighths-s {
    width: (100% / 8) * 5;
}

.grid-item.five-ninth-s,
.grid-item.five-ninths-s {
    width: (100% / 9) * 5;
}

.grid-item.five-tenth-s,
.grid-item.five-tenths-s {
    width: (100% / 10) * 5;
}

// sixes

.grid-item.six-sixth-s,
.grid-item.six-sixths-s {
    width: (100% / 6) * 6;
}

.grid-item.six-seventh-s,
.grid-item.six-sevenths-s {
    width: (100% / 7) * 6;
}

.grid-item.six-eighth-s,
.grid-item.six-eighths-s {
    width: (100% / 8) * 6;
}

.grid-item.six-ninth-s,
.grid-item.six-ninths-s {
    width: (100% / 9) * 6;
}

.grid-item.six-tenth-s,
.grid-item.six-tenths-s {
    width: (100% / 10) * 6;
}

// sevens

.grid-item.seven-seventh-s,
.grid-item.seven-sevenths-s {
    width: (100% / 7) * 7;
}

.grid-item.seven-eighth-s,
.grid-item.seven-eighths-s {
    width: (100% / 8) * 7;
}

.grid-item.seven-ninth-s,
.grid-item.seven-ninths-s {
    width: (100% / 9) * 7;
}

.grid-item.seven-tenth-s,
.grid-item.seven-tenths-s {
    width: (100% / 10) * 7;
}

// eights

.grid-item.eight-eighth-s,
.grid-item.eight-eighths-s {
    width: (100% / 8) * 8;
}

.grid-item.eight-ninth-s,
.grid-item.eight-ninths-s {
    width: (100% / 9) * 8;
}

.grid-item.eight-tenth-s,
.grid-item.eight-tenths-s {
    width: (100% / 10) * 8;
}

// nines

.grid-item.nine-ninth-s,
.grid-item.nine-ninths-s {
    width: (100% / 9) * 9;
}

.grid-item.nine-tenth-s,
.grid-item.nine-tenths-s {
    width: (100% / 10) * 9;
}

// tens

.grid-item.ten-tenth-s,
.grid-item.ten-tenths-s {
    width: (100% / 10) * 10;
}
