// Styles written by YOURNAME @ YOURCOMPANY

/* ------------------------------------------------------------------------ *\
 * Swiper                                                                   *
\* ------------------------------------------------------------------------ */

// import base styles

@import "_swiper-vendor";

// custom styles

.swiper-container {
    & {
        background: $background_alt;
    }
}

.swiper-slide {
    margin: 0;
    
    &.swiper-slide-active {
        z-index: 999;
    }
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.swiper-caption {
    background: $page_background;
    padding: remify(10 10 50, 16);
    position: relative;
    
    :not(.swiper-slide-active) & {
        opacity: 0;
        transition: opacity 2000ms, visibility 0s linear 2000ms;
        visibility: hidden;
    }
    
    .swiper-slide-active & {
        opacity: 1;
        transition: opacity 0s, visibility 0s linear 0s;
        visibility: visible;
    }
}

.swiper-caption .hdg.hdg2 {
    font-size: remify(15, 16);
}

.swiper-caption .txt {
    color: $background;
    margin: 0;
}

.swiper-caption .btn {
    bottom: 0;
    display: block;
    font-size: remify(15, 16);
    left: 0;
    position: absolute;
    width: 100%;
}

// display the first slide in older browsers

.no-csstransforms .swiper-slide:not(:first-child) {
    & {
        display: none;
    }
}
