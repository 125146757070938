// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Slideshow                                                                *
\* ------------------------------------------------------------------------ */

.slideshow-wrapper {
    padding-bottom: 50px;
    padding-bottom: remify(50);
    padding-top: 50px;
    padding-top: remify(50);
}

.slideshow-wrapper .slideshow-menu .menu-list {
    font-size: remify(18, 14);
}

.slideshow-wrapper .slideshow-menu .menu-item .thumbnail-text {
    background-size: remify(80, 20) auto;
    padding-left: remify(100, 20);
}
