@charset "UTF-8";
/* ------------------------------------------------------------------------ * * Utilities                                                                *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Colors                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Fonts                                                                    *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Widths                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Break Points                                                             *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layers                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * FontAwesome Icons (http://fortawesome.github.io/Font-Awesome/icons/)     *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Functions                                                                *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Mixins                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Base                                                                     *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Normalize                                                                *
\* ------------------------------------------------------------------------ */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

::-webkit-outer-spin-button,
::-webkit-inner-spin-button,
::-webkit-calendar-picker-indicator {
  appearance: none;
  display: none;
  margin: 0;
  -webkit-appearance: none; }

input[type="number"] {
  -moz-appearance: textfield; }

address {
  font-style: normal; }

/* ------------------------------------------------------------------------ * * Grid                                                                     *
\* ------------------------------------------------------------------------ */
.grid {
  margin-left: -50px;
  margin-left: -3.125rem; }

.grid-item {
  display: inline-block;
  padding-left: 50px;
  padding-left: 3.125rem;
  vertical-align: top;
  width: 100%; }

.grid-item.full {
  width: 100%; }

.grid-item.one-half {
  width: 50%; }

.grid-item.one-third {
  width: 33.33333%; }

.grid-item.one-fourth {
  width: 25%; }

.grid-item.one-fifth {
  width: 20%; }

.grid-item.one-sixth {
  width: 16.66667%; }

.grid-item.one-seventh {
  width: 14.28571%; }

.grid-item.one-eighth {
  width: 12.5%; }

.grid-item.one-ninth {
  width: 11.11111%; }

.grid-item.one-tenth {
  width: 10%; }

.grid-item.two-half,
.grid-item.two-halfs,
.grid-item.two-halves {
  width: 66.66667%; }

.grid-item.two-third,
.grid-item.two-thirds {
  width: 66.66667%; }

.grid-item.two-fourth,
.grid-item.two-fourths {
  width: 50%; }

.grid-item.two-fifth,
.grid-item.two-fifths {
  width: 40%; }

.grid-item.two-sixth,
.grid-item.two-sixths {
  width: 33.33333%; }

.grid-item.two-seventh,
.grid-item.two-sevenths {
  width: 28.57143%; }

.grid-item.two-eighth,
.grid-item.two-eighths {
  width: 25%; }

.grid-item.two-ninth,
.grid-item.two-ninths {
  width: 22.22222%; }

.grid-item.two-tenth,
.grid-item.two-tenths {
  width: 20%; }

.grid-item.three-third,
.grid-item.three-thirds {
  width: 100%; }

.grid-item.three-fourth,
.grid-item.three-fourths {
  width: 75%; }

.grid-item.three-fifth,
.grid-item.three-fifths {
  width: 60%; }

.grid-item.three-sixth,
.grid-item.three-sixths {
  width: 50%; }

.grid-item.three-seventh,
.grid-item.three-sevenths {
  width: 42.85714%; }

.grid-item.three-eighth,
.grid-item.three-eighths {
  width: 37.5%; }

.grid-item.three-ninth,
.grid-item.three-ninths {
  width: 33.33333%; }

.grid-item.three-tenth,
.grid-item.three-tenths {
  width: 30%; }

.grid-item.four-fourth,
.grid-item.four-fourths {
  width: 100%; }

.grid-item.four-fifth,
.grid-item.four-fifths {
  width: 80%; }

.grid-item.four-sixth,
.grid-item.four-sixths {
  width: 66.66667%; }

.grid-item.four-seventh,
.grid-item.four-sevenths {
  width: 57.14286%; }

.grid-item.four-eighth,
.grid-item.four-eighths {
  width: 50%; }

.grid-item.four-ninth,
.grid-item.four-ninths {
  width: 44.44444%; }

.grid-item.four-tenth,
.grid-item.four-tenths {
  width: 40%; }

.grid-item.five-fifth,
.grid-item.five-fifths {
  width: 100%; }

.grid-item.five-sixth,
.grid-item.five-sixths {
  width: 83.33333%; }

.grid-item.five-seventh,
.grid-item.five-sevenths {
  width: 71.42857%; }

.grid-item.five-eighth,
.grid-item.five-eighths {
  width: 62.5%; }

.grid-item.five-ninth,
.grid-item.five-ninths {
  width: 55.55556%; }

.grid-item.five-tenth,
.grid-item.five-tenths {
  width: 50%; }

.grid-item.six-sixth,
.grid-item.six-sixths {
  width: 100%; }

.grid-item.six-seventh,
.grid-item.six-sevenths {
  width: 85.71429%; }

.grid-item.six-eighth,
.grid-item.six-eighths {
  width: 75%; }

.grid-item.six-ninth,
.grid-item.six-ninths {
  width: 66.66667%; }

.grid-item.six-tenth,
.grid-item.six-tenths {
  width: 60%; }

.grid-item.seven-seventh,
.grid-item.seven-sevenths {
  width: 100%; }

.grid-item.seven-eighth,
.grid-item.seven-eighths {
  width: 87.5%; }

.grid-item.seven-ninth,
.grid-item.seven-ninths {
  width: 77.77778%; }

.grid-item.seven-tenth,
.grid-item.seven-tenths {
  width: 70%; }

.grid-item.eight-eighth,
.grid-item.eight-eighths {
  width: 100%; }

.grid-item.eight-ninth,
.grid-item.eight-ninths {
  width: 88.88889%; }

.grid-item.eight-tenth,
.grid-item.eight-tenths {
  width: 80%; }

.grid-item.nine-ninth,
.grid-item.nine-ninths {
  width: 100%; }

.grid-item.nine-tenth,
.grid-item.nine-tenths {
  width: 90%; }

.grid-item.ten-tenth,
.grid-item.ten-tenths {
  width: 100%; }

/* ------------------------------------------------------------------------ * * Text Elements                                                            *
\* ------------------------------------------------------------------------ */
::selection {
  background: #FF843D;
  color: #FFFFFF; }

.hdg {
  color: #F37022;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-weight: 700;
  text-transform: uppercase; }

.hdg.hdg1,
.hdg.hdg2 {
  font-size: 15px;
  font-size: 0.9375rem;
  margin: 0em 0em 0.26667em; }

.hdg.hdg3,
.hdg.hdg4 {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0em; }

.hdg.hdg5 {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0em; }

.hdg.hdg6 {
  color: #2A2A2A;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 400;
  margin: 0em; }

.txt {
  color: #2A2A2A;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.25em;
  margin: 0em 0em 2.33333em; }

.link {
  color: #F37022;
  text-decoration: none;
  transition: color 0.15s; }
  .link:hover {
    color: #FF843D; }

/* ------------------------------------------------------------------------ * * Generic Form Elements                                                    *
\* ------------------------------------------------------------------------ */
.btn {
  background: #F37022;
  color: #FFFFFF;
  display: inline-block;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  padding: 0.6em 1.06667em 0.53333em;
  pointer-events: all;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.15s; }
  .btn:hover {
    background: #FF843D; }

/* ------------------------------------------------------------------------ * * User Content                                                             *
\* ------------------------------------------------------------------------ */
.user-content > *:last-child,
.user-content > .grid:last-child .grid-item *:last-child {
  margin-bottom: 0 !important; }

.user-content h1,
.user-content h2,
.user-content h3,
.user-content h4,
.user-content h5,
.user-content h6 {
  color: #F37022;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  text-transform: uppercase; }

.user-content h1,
.user-content h2 {
  font-size: 15px;
  font-size: 0.9375rem;
  margin: 0em 0em 0.26667em; }

.user-content h1 {
  margin: 0; }

.user-content h2 {
  margin: 0em 0em 0.53333em; }

.user-content h3 {
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0em; }

.user-content h4 {
  color: #2A2A2A;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0em; }
  .single-product .user-content h4 {
    margin: 0em 0em 1.42857em; }

.user-content h5 {
  font-size: 12px;
  font-size: 0.75rem;
  margin: 0em; }

.user-content h6 {
  color: #2A2A2A;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0; }

.user-content menu,
.user-content ol,
.user-content p,
.user-content table,
.user-content ul {
  color: #2A2A2A;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.25em;
  margin: 0em 0em 1.25em; }

.user-content menu,
.user-content ol,
.user-content ul {
  padding: 0em 0em 0em 1.5em; }
  .user-content menu menu,
  .user-content menu ol,
  .user-content menu ul,
  .user-content ol menu,
  .user-content ol ol,
  .user-content ol ul,
  .user-content ul menu,
  .user-content ul ol,
  .user-content ul ul {
    margin-bottom: 0; }
  .user-content menu li,
  .user-content ol li,
  .user-content ul li {
    margin: 0em 0em 0.25em; }

.content-wrapper:nth-of-type(even) .user-content table tr:not(:last-child) td,
.content-wrapper:nth-of-type(even) .user-content table tr:not(:last-child) th {
  border-bottom: 1px solid #767676; }

.user-content table.blades {
  width: auto; }
  .user-content table.blades > tbody {
    width: 750px;
    display: block; }

.header-wrapper + .content-wrapper .user-content table {
  width: auto; }

.user-content table {
  display: block;
  overflow: auto;
  padding-bottom: 1px;
  padding-bottom: 0.0625em;
  table-layout: fixed;
  width: 100%;
  -webkit-overflow-scrolling: touch; }
  .user-content table.stack {
    overflow: hidden;
    width: auto; }
    .user-content table.stack thead,
    .user-content table.stack tbody,
    .user-content table.stack tfoot,
    .user-content table.stack tr,
    .user-content table.stack th,
    .user-content table.stack td {
      display: block; }
    .user-content table.stack th:not(:first-child),
    .user-content table.stack td:not(:first-child) {
      padding-top: 0; }
    .user-content table.stack th:not(:last-child),
    .user-content table.stack td:not(:last-child) {
      border-bottom-width: 0; }
    .user-content table.stack th:not(:last-child) {
      padding-bottom: 0; }

.user-content table th,
.user-content table td {
  border-bottom: 1px solid #d6d6d6;
  padding: 1em 1em 1em 0em;
  vertical-align: top; }
  .user-content table th.rating img,
  .user-content table td.rating img {
    margin: 0;
    padding: 0 3px;
    height: 15px; }

.user-content table *:last-child tr:last-child td,
.user-content table *:last-child tr:last-child th,
.user-content table > tr:last-child td,
.user-content table > tr:last-child th {
  border-bottom: 0; }

.user-content table th,
.user-content table thead td {
  color: #F37022;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase; }

.user-content table tfoot td,
.user-content table tfoot th {
  font-style: italic; }

.user-content table menu,
.user-content table ol,
.user-content table p,
.user-content table table,
.user-content table ul {
  margin: 0em 0em 1.66667em; }
  .user-content table menu:last-child,
  .user-content table ol:last-child,
  .user-content table p:last-child,
  .user-content table table:last-child,
  .user-content table ul:last-child {
    margin-bottom: 0 !important; }

.user-content a {
  color: #F37022;
  text-decoration: none;
  transition: color 0.15s; }
  .user-content a:hover {
    color: #FF843D; }

.user-content pre {
  color: #2A2A2A;
  font-family: monospace;
  font-size: 16px;
  font-size: 1rem; }

.user-content input,
.user-content label,
.user-content .select,
.user-content select,
.user-content textarea {
  color: #2A2A2A;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5em; }

.user-content label {
  display: block;
  margin: 0em 0em 0.625em; }
  .user-content label:hover {
    cursor: pointer; }

::-webkit-input-placeholder {
  color: #2A2A2A;
  opacity: 1; }

:-moz-placeholder {
  color: #2A2A2A;
  opacity: 1; }

::-moz-placeholder {
  color: #2A2A2A;
  opacity: 1; }

:-ms-input-placeholder {
  color: #2A2A2A;
  opacity: 1; }

.placeholder {
  color: #2A2A2A;
  opacity: 1; }

.user-content button,
.user-content input[type=button],
.user-content input[type=reset],
.user-content input[type=submit],
.user-content .button {
  background: #F37022;
  border: 0;
  color: #FFFFFF;
  display: inline-block;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 700;
  margin: 0em 0em 0.66667em;
  padding: 0.73333em 1.33333em 0.66667em;
  text-transform: uppercase;
  transition: background 0.15s, color 0.15s; }
  .user-content button:hover,
  .user-content input[type=button]:hover,
  .user-content input[type=reset]:hover,
  .user-content input[type=submit]:hover,
  .user-content .button:hover {
    background: #FF843D;
    color: #FFFFFF; }

.user-content p .button {
  margin: 0; }

.user-content input[type=checkbox],
.user-content input[type=radio] {
  left: -999em;
  position: absolute; }
  .user-content input[type=checkbox] + label,
  .user-content input[type=radio] + label {
    position: relative;
    padding: 0.5625em 0em 0.6875em 3.25em; }
    .user-content input[type=checkbox] + label:before,
    .user-content input[type=radio] + label:before {
      background: #FFFFFF;
      border: 1px solid #F37022;
      border-width: 0.0625rem;
      color: #2A2A2A;
      content: "\0020";
      display: block;
      float: left;
      font-size: 1.5625em;
      height: 1.76em;
      left: 0;
      line-height: 1.64em;
      position: absolute;
      text-align: center;
      top: 0;
      width: 1.76em; }
      .-webkit- .user-content input[type=checkbox] + label:before:active,
      .-webkit- .user-content input[type=checkbox] + label:before:focus, .-webkit-
      .user-content input[type=radio] + label:before:active,
      .-webkit-
      .user-content input[type=radio] + label:before:focus {
        outline: -webkit-focus-ring-color auto 5px; }
      .-ms- .user-content input[type=checkbox] + label:before:active,
      .-ms- .user-content input[type=checkbox] + label:before:focus, .-ms-
      .user-content input[type=radio] + label:before:active,
      .-ms-
      .user-content input[type=radio] + label:before:focus {
        outline: #808080 dashed 1px; }
    .user-content input[type=checkbox] + label:hover,
    .user-content input[type=radio] + label:hover {
      cursor: pointer; }

.user-content input[type=checkbox]:focus + label:before,
.user-content input[type=radio]:focus + label:before {
  outline: -webkit-focus-ring-color auto 5px; }

.user-content input[type=checkbox][disabled] + label:before,
.user-content input[type=radio][disabled] + label:before {
  background: #D5D5D5; }

.user-content input[type=checkbox]:checked + label:before {
  content: "";
  font-family: "fontawesome"; }

.user-content input[type=radio] + label:before {
  border-radius: 50%; }

.user-content input[type=radio]:checked + label:before {
  content: "";
  font-family: "fontawesome"; }

.user-content input[type=color],
.user-content input[type=date],
.user-content input[type=datetime],
.user-content input[type=datetime-local],
.user-content input[type=email],
.user-content input[type=month],
.user-content input[type=number],
.user-content input[type=password],
.user-content input[type=range],
.user-content input[type=search],
.user-content input[type=tel],
.user-content input[type=text],
.user-content input[type=time],
.user-content input[type=url],
.user-content input[type=week],
.user-content .select,
.user-content textarea {
  background: #FFFFFF;
  border: 1px solid #F37022;
  border-radius: 0.33333em;
  box-sizing: border-box;
  display: block;
  margin: 0em 0em 0.83333em;
  outline: none;
  overflow: hidden;
  padding: 0.66667em 1em 0.66667em;
  text-overflow: ellipsis;
  width: 100%; }

.user-content input[type=color] {
  height: 3em;
  padding: 0; }

.user-content .select {
  overflow: visible;
  padding: 0;
  position: relative; }

.user-content .select:before {
  content: "";
  font-family: "fontawesome";
  color: #FFFFFF;
  display: block;
  font-size: 1.5em;
  line-height: normal;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 1.75em;
  z-index: 2; }

.user-content .select:after {
  background: #F37022;
  bottom: 0;
  content: "\0020";
  display: block;
  font-size: 1.5em;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.75em;
  z-index: 1; }

.user-content .select select {
  background: none;
  border: 0;
  display: block;
  margin: 0;
  outline: none;
  padding: 0.5625em 3.375em 0.6875em 0.75em;
  width: 100%;
  text-indent: 0.01px;
  text-overflow: "";
  -moz-appearance: none;
  -webkit-appearance: none; }
  .user-content .select select::-ms-expand {
    display: none; }
  .user-content .select select:hover {
    cursor: pointer; }

.user-content textarea {
  max-width: 100%;
  min-height: 6.25em;
  min-width: 100%; }

.user-content .select option {
  background: #FFF;
  color: #000; }
  .user-content .select option:hover {
    background: #DDD; }

.user-content blockquote {
  border-left: 5px solid #F37022;
  margin: 0 0 24px;
  margin-bottom: 1.5rem;
  padding: 0 0 0 24px;
  padding-left: 1.5rem; }

.user-content hr {
  border: 0;
  border-bottom: 1px solid #F37022;
  margin: 0 0 24px;
  margin-bottom: 1.5rem; }

.user-content img {
  height: auto;
  max-width: 100%; }

.user-content pre {
  background: #767676;
  border: 1px solid #2A2A2A;
  color: #FFFFFF;
  display: block;
  font-family: monospace;
  font-size: 0.75em;
  font-weight: 400;
  margin: 0em 0em 1em;
  padding: 0.83333em 1em;
  width: 100%; }

/* ------------------------------------------------------------------------ * * Page                                                                     *
\* ------------------------------------------------------------------------ */
html,
body {
  width: 100%; }

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility; }

body {
  background: #FFFFFF; }

@-ms-viewport {
  width: device-width; }

/* ------------------------------------------------------------------------ * * Layout                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Page                                                                     *
\* ------------------------------------------------------------------------ */
.page-wrapper {
  background: #FFFFFF;
  font-size: 16px;
  font-size: 1rem;
  left: 0;
  min-height: 100%;
  position: relative;
  transition: box-shadow 0.15s, left 0.15s;
  width: 100%;
  z-index: 2; }

/* ------------------------------------------------------------------------ * * Header                                                                   *
\* ------------------------------------------------------------------------ */
.header-wrapper {
  position: relative;
  z-index: 4;
  background: #000000;
  font-size: 16px;
  font-size: 1rem; }

.header {
  *zoom: 1;
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
  max-width: 75rem; }
  .header:before, .header:after {
    content: "";
    display: table; }
  .header:after {
    clear: both; }

.header .header-left {
  background: #F37022;
  flex: 1 1 auto;
  float: left;
  position: relative; }
  .flexbox .header .header-left {
    float: none; }
  .header .header-left:after {
    border-bottom: 2.34375em solid transparent;
    border-right: 2.34375em solid #000000;
    border-top: 2.34375em solid transparent;
    content: "\0020";
    display: block;
    height: 0;
    margin-top: -2.34375em;
    position: absolute;
    right: 0;
    top: 50%;
    width: 0; }

.header .header-right {
  flex: none;
  float: right;
  position: relative;
  width: 4.6875em; }
  .flexbox .header .header-right {
    float: none; }

.header .logo {
  padding: 0.625em 1.5em;
  width: 10.5em; }

.header .menu-button {
  margin: 1.0625em 0em 1.0625em 0.9375em; }

/* ------------------------------------------------------------------------ * * Mobile Navigation                                                        *
\* ------------------------------------------------------------------------ */
.mobile-nav-wrapper {
  background: #000000;
  bottom: 0;
  font-size: 16px;
  font-size: 1rem;
  overflow: auto;
  position: absolute;
  right: 999em;
  top: -999em;
  transition: right 0s 0.15s, top 0s 0.15s;
  width: 240px;
  width: 15rem;
  z-index: 1; }

.mobile-nav .search-form {
  border-radius: 0.25em;
  margin: 0.875em; }

.mobile-nav .hdg.hdg4 {
  color: #FFFFFF;
  font-size: 1.25em;
  margin: 0em 0em 0.7em;
  text-align: center; }

.mobile-nav .hdg.hdg4 a {
  color: #FFFFFF;
  text-decoration: none;
  transition: color 0.15s; }
  .mobile-nav .hdg.hdg4 a:hover {
    color: #D5D5D5; }

.mobile-nav .social {
  padding: 0em 0.875em 0.625em; }

.mobile-nav .menu-list {
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif; }

.mobile-nav .l-vertical .menu-list {
  text-align: right; }

.mobile-nav .primary .menu-list {
  font-size: 1.25em;
  font-weight: 700;
  text-transform: uppercase; }

.mobile-nav .primary .menu-list.sub-menu {
  background: #1a1a1a;
  font-size: 0.9em; }

.mobile-nav .primary .menu-list.sub-menu .menu-list.sub-menu {
  font-size: 0.77778em;
  font-weight: 400;
  line-height: 1.42857em; }

.mobile-nav .primary .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu {
  font-size: 1em;
  line-height: 1.42857em;
  text-transform: none; }

.mobile-nav .primary .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu {
  padding-left: 0.71429em; }

.mobile-nav .secondary .menu-list {
  font-size: 1em; }

.mobile-nav .social .menu-list {
  font-size: 0.875em; }

.mobile-nav .social .menu-item:not(:last-child) {
  margin: 0em 0.4em 0em 0em; }

.mobile-nav .menu-item a {
  color: #F37022;
  padding: 0.4em 0.7em;
  text-decoration: none; }
  .mobile-nav .menu-item a:hover {
    color: #FF843D; }

.mobile-nav .menu-list.sub-menu .menu-item a {
  padding: 0.44444em 0.77778em; }

.mobile-nav .menu-list.sub-menu .menu-list.sub-menu .menu-item a {
  padding: 0.57143em 1em; }

.mobile-nav .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu .menu-item a {
  color: #D5D5D5; }
  .mobile-nav .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu .menu-item a:hover {
    color: #F37022; }
  .mobile-nav .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu .menu-item a:after {
    content: " -"; }

.mobile-nav .menu-toggle {
  border: 0;
  font-size: 0.75em;
  left: 0;
  right: auto; }

/* ------------------------------------------------------------------------ * * Slideshow                                                                *
\* ------------------------------------------------------------------------ */
.slideshow-wrapper {
  position: relative;
  z-index: 3;
  background: #D5D5D5;
  font-size: 16px;
  font-size: 1rem;
  padding: 20px;
  padding: 1.25rem; }

.slideshow {
  background: #FFFFFF;
  margin: 0 auto 20px;
  margin: 0 auto 1.25rem;
  max-width: 1200px;
  max-width: 75rem;
  position: relative; }
  .slideshow:last-child {
    margin-bottom: 0; }

.slideshow-wrapper .slideshow-menu {
  *zoom: 1;
  margin: 0 auto;
  max-width: 1200px;
  max-width: 75rem; }
  .slideshow-wrapper .slideshow-menu:before, .slideshow-wrapper .slideshow-menu:after {
    content: "";
    display: table; }
  .slideshow-wrapper .slideshow-menu:after {
    clear: both; }

.slideshow-wrapper .slideshow-menu .menu-list {
  color: #FFFFFF;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 0.875em;
  font-weight: 700;
  line-height: 1.25em;
  margin: 0;
  text-align: left;
  text-transform: uppercase; }

.slideshow-wrapper .slideshow-menu .menu-item {
  float: none; }

.slideshow-wrapper .slideshow-menu .menu-item:not(:last-child) {
  margin: 0 0 1px; }

.slideshow-wrapper .slideshow-menu .menu-item .thumbnail-text {
  background: #000000;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 2.66667em auto;
  color: #FFFFFF;
  overflow: hidden;
  padding: 1.4em 0.93333em 1.33333em 3.33333em;
  text-decoration: none;
  transition: background 0.15s; }
  .slideshow-wrapper .slideshow-menu .menu-item .thumbnail-text:hover {
    background-color: #1a1a1a; }

.slideshow-wrapper .slideshow-menu .menu-item.active .thumbnail-text {
  background-color: #F37022;
  cursor: pointer;
  position: relative; }
  .slideshow-wrapper .slideshow-menu .menu-item.active .thumbnail-text:after {
    border-bottom: 2em solid transparent;
    border-right: 2em solid #cc5e1d;
    border-top: 2em solid transparent;
    content: "\0020";
    display: block;
    height: 0;
    margin-top: -2em;
    position: absolute;
    right: 0;
    top: 50%;
    transition: border-color 0.15s;
    width: 0; }

/* ------------------------------------------------------------------------ * * Content                                                                  *
\* ------------------------------------------------------------------------ */
.content-wrapper {
  position: relative;
  z-index: 2;
  background: #D5D5D5;
  font-size: 16px;
  font-size: 1rem;
  padding: 22px 25px;
  padding: 1.34375rem 1.5625rem; }
  .content-wrapper:nth-of-type(odd) {
    background: #FFFFFF; }

.content {
  *zoom: 1;
  margin: 0 auto;
  max-width: 1200px;
  max-width: 75rem; }
  .content:before, .content:after {
    content: "";
    display: table; }
  .content:after {
    clear: both; }

.content .sidebar {
  padding-bottom: 1.25em; }

.single-product .post {
  display: flex;
  flex-direction: column; }

.single-product .post .product-left {
  order: 2; }

.single-product .post .product-right {
  order: 1; }

/* ------------------------------------------------------------------------ * * Footer                                                                   *
\* ------------------------------------------------------------------------ */
.footer-wrapper {
  position: relative;
  z-index: 1;
  background: #F37022;
  font-size: 16px;
  font-size: 1rem;
  padding: 30px 20px;
  padding: 1.875rem 1.25rem; }

.footer {
  *zoom: 1;
  margin: 0 auto;
  max-width: 1200px;
  max-width: 75rem; }
  .footer:before, .footer:after {
    content: "";
    display: table; }
  .footer:after {
    clear: both; }

.footer .logo {
  margin: 0 auto 1.625em;
  width: 11.25em; }

.footer .txt.txtp {
  color: #FFFFFF;
  font-size: 0.75em;
  line-height: 1.25em;
  margin: 0em 0em 1.16667em;
  text-align: center; }
  .footer .txt.txtp:last-child {
    margin-bottom: 0; }

.footer .txt.txtp a {
  color: #FFFFFF;
  text-decoration: none;
  transition: color 0.15s; }
  .footer .txt.txtp a:hover {
    color: background_alt; }

/* ------------------------------------------------------------------------ * * Module                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Align                                                                    *
\* ------------------------------------------------------------------------ */
.aligncenter {
  display: block;
  margin-bottom: 1.875em; }

.alignleft {
  display: block;
  float: none;
  margin: 0 auto 1.875em; }

.alignright {
  display: block;
  float: none;
  margin: 0 auto 1.875em; }

/* ------------------------------------------------------------------------ * * Comments                                                                 *
\* ------------------------------------------------------------------------ */
.commentlist .comment .comment-body .comment-author,
.commentlist .comment .comment-body .comment-meta {
  color: #2A2A2A;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 1em;
  font-weight: normal;
  line-height: 2em;
  margin: 0em 0em 1em; }

.commentlist {
  clear: both;
  list-style: none;
  padding: 0 !important; }

.commentlist .comment {
  padding: 0; }
  .commentlist .comment:before {
    display: none; }

.commentlist .comment-body {
  background: #DDD;
  float: none;
  padding: 1em;
  width: auto; }

.commentlist .comment-author {
  *zoom: 1;
  float: left;
  text-align: left; }
  .commentlist .comment-author:before, .commentlist .comment-author:after {
    content: "";
    display: table; }
  .commentlist .comment-author:after {
    clear: both; }

.commentlist .comment-author img {
  float: left !important;
  margin: 0em 0.625em 0em 0em !important; }

.commentlist .comment-meta {
  *zoom: 1;
  margin: 0 !important;
  padding: 0;
  text-align: left; }
  .commentlist .comment-meta:before, .commentlist .comment-meta:after {
    content: "";
    display: table; }
  .commentlist .comment-meta:after {
    clear: both; }

.commentlist .comment-meta .comment-author {
  float: left; }

.commentlist .comment-meta .comment-metadata {
  float: right; }

.commentlist p {
  clear: both; }

.commentlist .reply {
  text-align: right; }

.commentlist ul.children {
  list-style: none;
  padding-left: 1em; }

.commentlist ul.children .comment-body {
  background: #E5E5E5; }

.commentlist ul.children ul.children .comment-body {
  background: #E5E5E5; }

.commentlist ul.children ul.children ul.children .comment-body {
  background: #EDEDED; }

.commentlist ul.children ul.children ul.children ul.children .comment-body {
  background: #F5F5F5; }

.commentlist ul.children ul.children ul.children ul.children .comment-body {
  background: #FCFCFC; }

#respond {
  clear: both; }

#respond textarea {
  height: 6.25em !important;
  resize: none !important; }

#respond input {
  margin-bottom: 0 !important; }

/* ------------------------------------------------------------------------ * * Gallery                                                                  *
\* ------------------------------------------------------------------------ */
.gallery {
  *zoom: 1;
  margin: 0em 0em 0.625em; }
  .gallery:before, .gallery:after {
    content: "";
    display: table; }
  .gallery:after {
    clear: both; }

.gallery-images {
  background: #FFFFFF;
  height: 0;
  margin: 0em 0em 0.625em;
  padding-bottom: 100%;
  position: relative;
  width: 100%; }

.gallery-image {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.15s;
  z-index: 1; }
  .gallery-image.active {
    opacity: 1;
    z-index: 2; }

.gallery-image img {
  display: block;
  height: auto;
  max-width: none;
  width: 100%; }

.gallery-thumbnails {
  *zoom: 1;
  list-style: none;
  margin: 0;
  padding: 0; }
  .gallery-thumbnails:before, .gallery-thumbnails:after {
    content: "";
    display: table; }
  .gallery-thumbnails:after {
    clear: both; }

.gallery-thumbnail {
  float: left;
  margin: 0em 0.625em 0.625em 0em;
  width: 15%; }

.gallery-thumbnail button {
  background: none;
  border: 1px solid #2A2A2A;
  margin: 0;
  padding: 0;
  position: relative;
  transition: border-color 0.15s; }

.gallery-thumbnail.-magic360 button::before, .gallery-thumbnail.-magic360 button::after {
  display: block;
  content: "\0020"; }

.gallery-thumbnail.-magic360 button::before {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 2; }

.gallery-thumbnail.-magic360 button::after {
  display: block;
  content: "360°";
  color: #FFFFFF;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 0.875em;
  font-weight: 700;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 3; }

.gallery-thumbnail.active button {
  border-color: #F37022; }

.gallery-thumbnail img {
  display: block;
  height: auto;
  width: 100%; }

/* ------------------------------------------------------------------------ * * Hiders                                                                   *
\* ------------------------------------------------------------------------ */
.hide-xxs, .hide-xs, .hide-s, .hide-m, .hide-l, .hide-xl, .hide-xxl {
  display: block !important; }
  .hide-xxs.inline, .hide-xs.inline, .hide-s.inline, .hide-m.inline, .hide-l.inline, .hide-xl.inline, .hide-xxl.inline {
    display: inline-block !important; }

.show-xxs, .show-xs, .show-s, .show-m, .show-l, .show-xl, .show-xxl {
  display: none !important; }
  .show-xxs.inline, .show-xs.inline, .show-s.inline, .show-m.inline, .show-l.inline, .show-xl.inline, .show-xxl.inline {
    display: none !important; }

/* ------------------------------------------------------------------------ * * IE Warning                                                               *
\* ------------------------------------------------------------------------ */
.ie-warning-wrapper {
  background: #BF1313;
  padding: 0 20px; }

.ie-warning {
  margin: 0 auto;
  padding: 20px 0;
  width: 1200px; }

.ie-warning p {
  color: #FFF;
  display: block;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-align: center;
  text-transform: none; }

.ie-warning a {
  color: #FFF;
  text-decoration: underline; }
  .ie-warning a:hover {
    color: #FFF; }

/* ------------------------------------------------------------------------ * * iframe                                                                   *
\* ------------------------------------------------------------------------ */
.iframe {
  display: block;
  height: 0;
  position: relative;
  width: 100%; }
  .iframe iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }

/* ------------------------------------------------------------------------ * * jqueryFileTree                                                           *
\* ------------------------------------------------------------------------ */
.user-content ul.jqueryFileTree li {
  white-space: pre-wrap; }

.lb-loader, .lightbox {
  text-align: center;
  line-height: 0; }

body:after {
  content: url(../media/lightbox/close.png) url(../media/lightbox/loading.gif) url(../media/lightbox/prev.png) url(../media/lightbox/next.png);
  display: none; }

.lb-dataContainer:after, .lb-outerContainer:after {
  content: "";
  clear: both; }

body.lb-disable-scrolling {
  overflow: hidden; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: #000;
  filter: alpha(Opacity=80);
  opacity: .8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  font-weight: 400; }

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  border-radius: 3px; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  background-color: #fff;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px; }

.lb-loader, .lb-nav {
  position: absolute;
  left: 0; }

.lb-outerContainer:after {
  display: table; }

.lb-container {
  padding: 4px; }

.lb-loader {
  top: 43%;
  height: 25%;
  width: 100%; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../media/lightbox/loading.gif) no-repeat; }

.lb-nav {
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: 0;
  background-image: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==); }

.lb-next, .lb-prev {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../media/lightbox/prev.png) left 48% no-repeat;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s; }

.lb-nav a.lb-prev:hover {
  filter: alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../media/lightbox/next.png) right 48% no-repeat;
  filter: alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s; }

.lb-nav a.lb-next:hover {
  filter: alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.lb-dataContainer:after {
  display: table; }

.lb-data {
  padding: 0 4px;
  color: #ccc; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: 700;
  line-height: 1em; }

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999; }

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../media/lightbox/close.png) top right no-repeat;
  text-align: right;
  outline: 0;
  filter: alpha(Opacity=70);
  opacity: .7;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  -o-transition: opacity .2s;
  transition: opacity .2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: alpha(Opacity=100);
  opacity: 1; }

/* ------------------------------------------------------------------------ * * Login Form                                                            *
\* ------------------------------------------------------------------------ */
.login-form {
  border: 1px solid rgba(182, 182, 182, 0.35);
  display: block;
  font-size: 16px;
  font-size: 1rem;
  margin: 0 auto;
  max-width: 20em;
  padding: 1.25em; }

.login-input-wrapper {
  margin: 0em 0em 0.625em;
  position: relative; }

.login-button,
.login-input,
.login-label,
.login-notice {
  display: block;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  width: 100%; }

.login-button {
  background: #F37022;
  border: 0;
  color: #FFFFFF;
  font-size: 1em;
  font-weight: 700;
  padding: 0.5em 0.75em 0.4375em;
  text-align: left;
  text-transform: uppercase;
  transition: background 0.15s, color 0.15s; }
  .login-button:hover {
    background: #FF843D;
    color: #FFFFFF; }

.login-input {
  border: 1px solid #b6b6b6;
  border-radius: 0.25em;
  font-size: 1em;
  padding: 0.5em 0.75em 0.4375em; }

.login-label {
  font-size: 0.75em;
  text-transform: uppercase; }

.login-notice {
  font-size: 0.75em;
  text-align: right; }

.login-notice a {
  color: #2A2A2A;
  text-decoration: none;
  transition: color 0.15s; }
  .login-notice a:hover {
    color: #F37022; }

/* ------------------------------------------------------------------------ * * Logo                                                                     *
\* ------------------------------------------------------------------------ */
.logo {
  display: block; }

.logo img {
  display: block;
  width: 100%; }

/* ------------------------------------------------------------------------ * * Magic 360                                                                *
\* ------------------------------------------------------------------------ */
.Magic360-container {
  border: 0 !important;
  margin: 0 auto !important; }

/* ------------------------------------------------------------------------ * * Menu Buton                                                               *
\* ------------------------------------------------------------------------ */
.menu-button {
  background: none;
  border: 1px solid #7a3811;
  border-radius: 0.125em;
  color: #F37022;
  display: block;
  font-size: 16px;
  font-size: 1rem;
  height: 2.5625em;
  margin: 0;
  padding: 0;
  position: relative;
  text-indent: -999em;
  transition: background-color 0.15s;
  width: 2.5em; }
  .menu-button:hover {
    background: none;
    color: #F37022; }

.menu-button:before,
.menu-button:after {
  background: currentColor;
  bottom: 0;
  content: "\0020";
  display: block;
  height: 0.1875em;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transition: box-shadow 0.15s 0.15s, top 0.15s 0.15s, transform 0.15s;
  width: 1.25em; }
  .navopen .menu-button:before, .navopen
  .menu-button:after {
    transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s; }

.menu-button:before {
  box-shadow: 0em 0.375em 0em 0em currentColor;
  top: -0.75em; }
  .navopen .menu-button:before {
    box-shadow: 0 0 0 0 transparent;
    transform: rotate(45deg);
    top: 0; }

.menu-button:after {
  top: 0.75em; }
  .navopen .menu-button:after {
    transform: rotate(-45deg);
    top: 0; }

/* ------------------------------------------------------------------------ * * Menu List                                                                *
\* ------------------------------------------------------------------------ */
.menu-wrapper {
  *zoom: 1; }
  .menu-wrapper:before, .menu-wrapper:after {
    content: "";
    display: table; }
  .menu-wrapper:after {
    clear: both; }

.menu-list {
  *zoom: 1;
  font-size: 1em;
  line-height: normal;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center; }
  .menu-list:before, .menu-list:after {
    content: "";
    display: table; }
  .menu-list:after {
    clear: both; }
  .l-center .menu-list {
    float: right;
    right: 50%; }
  .l-vertical .menu-list {
    text-align: left; }

.menu-drop-down {
  float: none;
  right: auto;
  left: -999em;
  opacity: 0;
  position: absolute;
  text-align: left;
  top: -999em;
  transition: left 0s 0.15s, opacity 0.15s, top 0s 0.15s;
  width: 15em; }
  .menu-item:hover > .menu-drop-down {
    left: 0;
    opacity: 1;
    top: 100%;
    transition: opacity 0.15s; }
  .menu-item-mega-menu .menu-drop-down {
    display: flex;
    left: 0;
    right: 0;
    width: auto; }
  .l-vertical .menu-drop-down,
  .l-vertical .menu-item:hover > .menu-drop-down {
    left: auto;
    max-height: 0;
    opacity: 1;
    overflow: hidden;
    position: static;
    top: auto;
    transition: max-height 0.5s;
    width: auto; }

.menu-list.sub-menu {
  float: none;
  right: auto;
  left: auto;
  text-align: left;
  top: auto;
  width: auto; }
  .menu-item-mega-menu .menu-list.sub-menu {
    flex: 1 1 50%; }
  .l-vertical .menu-list.sub-menu {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s; }
  .l-vertical .is-open > .menu-list.sub-menu {
    transition: max-height 2.5s;
    max-height: 312.5em; }

.menu-item {
  float: left;
  position: relative; }
  .l-center .menu-item {
    left: 50%; }
  .l-vertical .menu-item {
    float: none; }
  .social .menu-item:not(:last-child) {
    margin: 0em 0.75em 0em 0em; }
  .menu-item.menu-item-mega-menu {
    position: static; }
  .menu-item-mega-menu .menu-item {
    display: flex;
    flex: 1 1 50%;
    flex-wrap: wrap;
    left: 0;
    position: static;
    right: 0;
    width: auto; }
  .menu-item-mega-menu .sub-menu .sub-menu .menu-item {
    display: block; }
  .sub-menu .menu-item {
    float: none;
    left: auto;
    width: 100%; }
  .menu-item.-right > .menu-drop-down {
    left: auto;
    right: 0; }

.menu-item a {
  display: block;
  padding: 0.625em 1.25em; }
  .menu-item-mega-menu .menu-item a {
    width: 100%; }

.social .menu-item a {
  color: #FFFFFF;
  text-decoration: none;
  transition: background 0.15s; }
  .social .menu-item a:hover {
    color: #FFFFFF; }
  .social .menu-item a:before {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    text-indent: 0;
    top: 0;
    width: 100%; }

.social .menu-item:not(.catalog) a {
  height: 2em;
  line-height: 2em;
  padding: 0;
  position: relative;
  text-align: center;
  text-indent: -999em;
  width: 2em; }

.social .menu-item.catalog a {
  background: #F37022;
  font-size: 0.6875em;
  line-height: 1.08727em;
  padding: 0.45455em 0.72727em 0.27273em 3.45455em;
  text-align: left;
  text-transform: uppercase; }
  .social .menu-item.catalog a:hover {
    background: #f68f52; }
  .social .menu-item.catalog a:before {
    content: "";
    font-family: "fontawesome";
    font-size: 1.63636em;
    left: 0.625em;
    line-height: normal;
    margin-top: -0.5em;
    top: 50%; }

.social .menu-item.facebook a {
  background: #3B5998; }
  .social .menu-item.facebook a:hover {
    background: #4c70ba; }
  .social .menu-item.facebook a:before {
    content: "";
    font-family: "fontawesome"; }

.social .menu-item.twitter a {
  background: #56A3D9; }
  .social .menu-item.twitter a:hover {
    background: #80bae2; }
  .social .menu-item.twitter a:before {
    content: "";
    font-family: "fontawesome"; }

.social .menu-item.youtube a {
  background: #BF221F; }
  .social .menu-item.youtube a:hover {
    background: #de3633; }
  .social .menu-item.youtube a:before {
    content: "";
    font-family: "fontawesome"; }

.menu-toggle {
  background: none;
  color: #F37022;
  height: 2.6875em;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-indent: -999em;
  top: 0;
  width: 2.6875em; }
  .menu-toggle:hover {
    background: none;
    color: #F37022; }
  .menu-toggle:before, .menu-toggle:after {
    background: currentColor;
    content: "\0020";
    display: block;
    height: 0.25em;
    position: absolute;
    top: 1.25em;
    transition: transform 0.15s;
    width: 0.625em; }
  .menu-toggle:before {
    left: 0.875em;
    transform: rotate(45deg); }
  .menu-toggle:after {
    right: 0.875em;
    transform: rotate(-45deg); }

/* ------------------------------------------------------------------------ * * Meta                                                                     *
\* ------------------------------------------------------------------------ */
.meta-list,
.user-content ul.meta-list {
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 0.75em;
  font-weight: 700;
  list-style: none;
  margin: 0em 0em 0.83333em;
  padding: 0;
  text-align: left;
  text-transform: uppercase; }

.meta-list.l-center {
  float: right;
  position: relative;
  right: 50%; }

.meta-list li {
  display: inline-block;
  padding: 0em 0.75em 0em 1.5em;
  position: relative; }

.meta-list li a {
  color: #F37022;
  text-decoration: none;
  transition: color 0.15s; }
  .meta-list li a:hover {
    color: #FF843D; }

.meta-list.l-center li {
  float: left;
  left: 50%;
  position: relative; }

.meta-list li:before {
  font-weight: 400;
  left: 0;
  position: absolute;
  top: 0; }

.meta-list li.comments:before {
  content: "";
  font-family: "fontawesome"; }

.meta-list li.categories:before {
  content: "";
  font-family: "fontawesome"; }

.meta-list li.tags:before {
  content: "";
  font-family: "fontawesome"; }

.meta-list li.time:before {
  content: "";
  font-family: "fontawesome"; }

.meta-list li.url:before {
  content: "";
  font-family: "fontawesome"; }

/* ------------------------------------------------------------------------ * * Mini Article                                                             *
\* ------------------------------------------------------------------------ */
.mini-article:not(:last-child) {
  margin: 0em 0em 1.25em; }

.mini-article a {
  color: #F37022;
  text-decoration: none;
  transition: color 0.15s; }
  .mini-article a:hover {
    color: #FF843D; }

/* ------------------------------------------------------------------------ * * Mini Product                                                             *
\* ------------------------------------------------------------------------ */
.mini-product-image {
  margin: 0em 0em 0.75em;
  width: 100%; }

.mini-product-image img {
  height: auto !important;
  width: 100%; }

.mini-product .hdg.hdg1 {
  font-size: 1.13969em;
  line-height: 0.83329em;
  margin: 0; }

.mini-product .hdg.hdg2 {
  font-weight: 400;
  line-height: 1.013em; }

.mini-product .hdg.hdg2 .link {
  color: #2A2A2A; }
  .mini-product .hdg.hdg2 .link:hover {
    color: #767676; }

/* ------------------------------------------------------------------------ * * Ninja Forms                                                              *
\* ------------------------------------------------------------------------ */
.ninja-forms-cont,
.ninja-forms-required-items {
  margin: 0 0 10px; }

.field-wrap {
  *zoom: 1;
  margin: 0 0 10px;
  visibility: visible !important; }
  .field-wrap:before, .field-wrap:after {
    content: "";
    display: table; }
  .field-wrap:after {
    clear: both; }
  .field-wrap input[type=checkbox],
  .field-wrap input[type=radio] {
    left: auto;
    position: relative; }
  .field-wrap select {
    width: 100%; }
  .field-wrap button,
  .field-wrap input[type=button],
  .field-wrap input[type=submit],
  .field-wrap input[type=reset],
  .field-wrap select {
    margin-left: 0 !important; }
  .field-wrap input,
  .field-wrap select,
  .field-wrap textarea {
    max-width: 100%;
    min-width: 0;
    width: 100%; }
  .field-wrap .ninja-forms-field-description p,
  .field-wrap.label-below .ninja-forms-field-description p {
    margin-bottom: 0;
    margin-top: 15px; }
  .field-wrap.checkbox-wrap.label-above label {
    margin-bottom: 0; }
  .field-wrap.checkbox-wrap.label-right label {
    padding-left: 0; }
  .field-wrap.label-below {
    margin-bottom: 20px; }
    .field-wrap.label-below input,
    .field-wrap.label-below select,
    .field-wrap.label-below textarea {
      margin-bottom: 0; }
  .field-wrap.label-left, .field-wrap.label-right {
    display: flex; }
    .field-wrap.label-left label, .field-wrap.label-right label {
      display: block;
      line-height: 100%;
      margin: 0; }
    .field-wrap.label-left input,
    .field-wrap.label-left .select,
    .field-wrap.label-left textarea, .field-wrap.label-right input,
    .field-wrap.label-right .select,
    .field-wrap.label-right textarea {
      display: block;
      flex: 1 1 auto;
      margin: 0; }
  .field-wrap.label-left label {
    padding-right: 6px; }
  .field-wrap.label-right label {
    padding-left: 6px; }

/* ------------------------------------------------------------------------ * * Search Form                                                              *
\* ------------------------------------------------------------------------ */
.search-form {
  background: #FFFFFF;
  border-radius: 0.75em;
  align-items: center;
  display: flex; }
  .modernizr-no-flexbox.modernizr-no-flexboxlegacy .search-form {
    position: relative;
    padding-right: 2.8125em; }

.search-label {
  left: -999em;
  position: absolute;
  top: -999em; }

.search-input {
  background: none;
  border: 0;
  flex: 1 1 auto;
  font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 0.875em;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  padding: 1em 0em 1em 1.14286em;
  text-overflow: ellipsis;
  width: 0; }
  .modernizr-no-flexbox.modernizr-no-flexboxlegacy .search-input {
    width: 100%; }

.search-submit {
  background: none;
  border: 0;
  color: #F37022;
  display: block;
  flex: none;
  font-size: 1em;
  height: 2.8125em;
  line-height: 2.8125em;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  text-indent: -999em;
  width: 2.8125em; }
  .search-submit:before {
    content: "";
    font-family: "fontawesome";
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    text-indent: 0;
    top: 0;
    width: 100%; }
  .modernizr-no-flexbox.modernizr-no-flexboxlegacy .search-submit {
    position: absolute;
    right: 0;
    top: 0; }

/* ------------------------------------------------------------------------ * * Swiper                                                                   *
\* ------------------------------------------------------------------------ */
/**
 * Swiper 3.3.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: February 7, 2016
 */
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height; }

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px; }

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5); }

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff; }

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000; }

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px; }

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible; }

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

.swiper-container {
  background: #D5D5D5; }

.swiper-slide {
  margin: 0; }
  .swiper-slide.swiper-slide-active {
    z-index: 999; }

.swiper-slide img {
  display: block;
  width: 100%; }

.swiper-caption {
  background: #000000;
  padding: 0.625em 0.625em 3.125em;
  position: relative; }
  :not(.swiper-slide-active) .swiper-caption {
    opacity: 0;
    transition: opacity 2000ms, visibility 0s linear 2000ms;
    visibility: hidden; }
  .swiper-slide-active .swiper-caption {
    opacity: 1;
    transition: opacity 0s, visibility 0s linear 0s;
    visibility: visible; }

.swiper-caption .hdg.hdg2 {
  font-size: 0.9375em; }

.swiper-caption .txt {
  color: #FFFFFF;
  margin: 0; }

.swiper-caption .btn {
  bottom: 0;
  display: block;
  font-size: 0.9375em;
  left: 0;
  position: absolute;
  width: 100%; }

.no-csstransforms .swiper-slide:not(:first-child) {
  display: none; }

/* ------------------------------------------------------------------------ * * wp-caption                                                               *
\* ------------------------------------------------------------------------ */
.wp-caption {
  background: #F37022;
  margin: 0em 0em 0.625em;
  margin: 0 auto 10px;
  padding: 0.375em;
  width: 18.75em; }

.wp-caption.full {
  width: auto; }

.wp-caption.no-back {
  background: none; }

.wp-caption img {
  display: block;
  margin: 0 auto; }

.wp-caption-text {
  color: #FFFFFF !important;
  font-style: italic;
  margin: 0;
  padding: 0.3em 1em 0.15em;
  text-align: center; }

.wp-caption.no-back .wp-caption-text {
  color: #2A2A2A !important; }

.wp-caption-text a {
  color: #FFFFFF;
  display: block; }

/* ------------------------------------------------------------------------ * * State                                                                    *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Menu Buton                                                               *
\* ------------------------------------------------------------------------ */
.is-navopen .menu-button:before,
.is-navopen .menu-button:after {
  transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s; }

.is-navopen .menu-button:before {
  box-shadow: 0 0 0 0 transparent;
  transform: rotate(45deg);
  top: 0; }

.is-navopen .menu-button:after {
  transform: rotate(-45deg);
  top: 0; }

/* ------------------------------------------------------------------------ * * Menu List                                                                *
\* ------------------------------------------------------------------------ */
.l-vertical .menu-item.is-open > .menu-list.sub-menu,
.l-vertical .menu-item.is-open:hover > .menu-list.sub-menu {
  max-height: 312.5em;
  transition: max-height 2.5s; }

.menu-item.is-open > .menu-toggle:before {
  transform: rotate(-45deg); }

.menu-item.is-open > .menu-toggle:after {
  transform: rotate(45deg); }

/* ------------------------------------------------------------------------ * * Mobile Navigation                                                        *
\* ------------------------------------------------------------------------ */
.is-navopen .mobile-nav-wrapper {
  right: 0;
  transition: none;
  top: 0; }

/* ------------------------------------------------------------------------ * * Page                                                                     *
\* ------------------------------------------------------------------------ */
html.is-navopen,
.is-navopen body {
  height: 100%;
  overflow: hidden;
  width: 100%; }

.is-navopen .page-wrapper {
  box-shadow: 0em 0em 0.625em rgba(0, 0, 0, 0.5);
  height: 100%;
  left: -240px;
  left: -15rem;
  overflow: hidden;
  width: 100%; }

@media screen and (min-width: 30rem) {
  /* ------------------------------------------------------------------------ * * Base                                                                     *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Grid                                                                     *
\* ------------------------------------------------------------------------ */
  .grid-item.full-xxs {
    width: 100%; }
  .grid-item.one-half-xxs {
    width: 50%; }
  .grid-item.one-third-xxs {
    width: 33.33333%; }
  .grid-item.one-fourth-xxs {
    width: 25%; }
  .grid-item.one-fifth-xxs {
    width: 20%; }
  .grid-item.one-sixth-xxs {
    width: 16.66667%; }
  .grid-item.one-seventh-xxs {
    width: 14.28571%; }
  .grid-item.one-eighth-xxs {
    width: 12.5%; }
  .grid-item.one-ninth-xxs {
    width: 11.11111%; }
  .grid-item.one-tenth-xxs {
    width: 10%; }
  .grid-item.two-half-xxs,
  .grid-item.two-halfs-xxs,
  .grid-item.two-halves-xxs {
    width: 66.66667%; }
  .grid-item.two-third-xxs,
  .grid-item.two-thirds-xxs {
    width: 66.66667%; }
  .grid-item.two-fourth-xxs,
  .grid-item.two-fourths-xxs {
    width: 50%; }
  .grid-item.two-fifth-xxs,
  .grid-item.two-fifths-xxs {
    width: 40%; }
  .grid-item.two-sixth-xxs,
  .grid-item.two-sixths-xxs {
    width: 33.33333%; }
  .grid-item.two-seventh-xxs,
  .grid-item.two-sevenths-xxs {
    width: 28.57143%; }
  .grid-item.two-eighth-xxs,
  .grid-item.two-eighths-xxs {
    width: 25%; }
  .grid-item.two-ninth-xxs,
  .grid-item.two-ninths-xxs {
    width: 22.22222%; }
  .grid-item.two-tenth-xxs,
  .grid-item.two-tenths-xxs {
    width: 20%; }
  .grid-item.three-third-xxs,
  .grid-item.three-thirds-xxs {
    width: 100%; }
  .grid-item.three-fourth-xxs,
  .grid-item.three-fourths-xxs {
    width: 75%; }
  .grid-item.three-fifth-xxs,
  .grid-item.three-fifths-xxs {
    width: 60%; }
  .grid-item.three-sixth-xxs,
  .grid-item.three-sixths-xxs {
    width: 50%; }
  .grid-item.three-seventh-xxs,
  .grid-item.three-sevenths-xxs {
    width: 42.85714%; }
  .grid-item.three-eighth-xxs,
  .grid-item.three-eighths-xxs {
    width: 37.5%; }
  .grid-item.three-ninth-xxs,
  .grid-item.three-ninths-xxs {
    width: 33.33333%; }
  .grid-item.three-tenth-xxs,
  .grid-item.three-tenths-xxs {
    width: 30%; }
  .grid-item.four-fourth-xxs,
  .grid-item.four-fourths-xxs {
    width: 100%; }
  .grid-item.four-fifth-xxs,
  .grid-item.four-fifths-xxs {
    width: 80%; }
  .grid-item.four-sixth-xxs,
  .grid-item.four-sixths-xxs {
    width: 66.66667%; }
  .grid-item.four-seventh-xxs,
  .grid-item.four-sevenths-xxs {
    width: 57.14286%; }
  .grid-item.four-eighth-xxs,
  .grid-item.four-eighths-xxs {
    width: 50%; }
  .grid-item.four-ninth-xxs,
  .grid-item.four-ninths-xxs {
    width: 44.44444%; }
  .grid-item.four-tenth-xxs,
  .grid-item.four-tenths-xxs {
    width: 40%; }
  .grid-item.five-fifth-xxs,
  .grid-item.five-fifths-xxs {
    width: 100%; }
  .grid-item.five-sixth-xxs,
  .grid-item.five-sixths-xxs {
    width: 83.33333%; }
  .grid-item.five-seventh-xxs,
  .grid-item.five-sevenths-xxs {
    width: 71.42857%; }
  .grid-item.five-eighth-xxs,
  .grid-item.five-eighths-xxs {
    width: 62.5%; }
  .grid-item.five-ninth-xxs,
  .grid-item.five-ninths-xxs {
    width: 55.55556%; }
  .grid-item.five-tenth-xxs,
  .grid-item.five-tenths-xxs {
    width: 50%; }
  .grid-item.six-sixth-xxs,
  .grid-item.six-sixths-xxs {
    width: 100%; }
  .grid-item.six-seventh-xxs,
  .grid-item.six-sevenths-xxs {
    width: 85.71429%; }
  .grid-item.six-eighth-xxs,
  .grid-item.six-eighths-xxs {
    width: 75%; }
  .grid-item.six-ninth-xxs,
  .grid-item.six-ninths-xxs {
    width: 66.66667%; }
  .grid-item.six-tenth-xxs,
  .grid-item.six-tenths-xxs {
    width: 60%; }
  .grid-item.seven-seventh-xxs,
  .grid-item.seven-sevenths-xxs {
    width: 100%; }
  .grid-item.seven-eighth-xxs,
  .grid-item.seven-eighths-xxs {
    width: 87.5%; }
  .grid-item.seven-ninth-xxs,
  .grid-item.seven-ninths-xxs {
    width: 77.77778%; }
  .grid-item.seven-tenth-xxs,
  .grid-item.seven-tenths-xxs {
    width: 70%; }
  .grid-item.eight-eighth-xxs,
  .grid-item.eight-eighths-xxs {
    width: 100%; }
  .grid-item.eight-ninth-xxs,
  .grid-item.eight-ninths-xxs {
    width: 88.88889%; }
  .grid-item.eight-tenth-xxs,
  .grid-item.eight-tenths-xxs {
    width: 80%; }
  .grid-item.nine-ninth-xxs,
  .grid-item.nine-ninths-xxs {
    width: 100%; }
  .grid-item.nine-tenth-xxs,
  .grid-item.nine-tenths-xxs {
    width: 90%; }
  .grid-item.ten-tenth-xxs,
  .grid-item.ten-tenths-xxs {
    width: 100%; }
  /* ------------------------------------------------------------------------ * * Layout                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Module                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Hiders                                                                   *
\* ------------------------------------------------------------------------ */
  .hide-xxs {
    display: none !important; }
    .hide-xxs.inline {
      display: none !important; }
  .show-xxs {
    display: block !important; }
    .show-xxs.inline {
      display: inline-block !important; }
  /* ------------------------------------------------------------------------ * * State                                                                    *
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 40rem) {
  /* ------------------------------------------------------------------------ * * Base                                                                     *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Grid                                                                     *
\* ------------------------------------------------------------------------ */
  .grid-item.full-xs {
    width: 100%; }
  .grid-item.one-half-xs {
    width: 49.71%; }
  .grid-item.one-third-xs {
    width: 33.33333%; }
  .grid-item.one-fourth-xs {
    width: 25%; }
  .grid-item.one-fifth-xs {
    width: 20%; }
  .grid-item.one-sixth-xs {
    width: 16.66667%; }
  .grid-item.one-seventh-xs {
    width: 14.28571%; }
  .grid-item.one-eighth-xs {
    width: 12.5%; }
  .grid-item.one-ninth-xs {
    width: 11.11111%; }
  .grid-item.one-tenth-xs {
    width: 10%; }
  .grid-item.two-half-xs,
  .grid-item.two-halfs-xs,
  .grid-item.two-halves-xs {
    width: 66.66667%; }
  .grid-item.two-third-xs,
  .grid-item.two-thirds-xs {
    width: 66.66667%; }
  .grid-item.two-fourth-xs,
  .grid-item.two-fourths-xs {
    width: 50%; }
  .grid-item.two-fifth-xs,
  .grid-item.two-fifths-xs {
    width: 40%; }
  .grid-item.two-sixth-xs,
  .grid-item.two-sixths-xs {
    width: 33.33333%; }
  .grid-item.two-seventh-xs,
  .grid-item.two-sevenths-xs {
    width: 28.57143%; }
  .grid-item.two-eighth-xs,
  .grid-item.two-eighths-xs {
    width: 25%; }
  .grid-item.two-ninth-xs,
  .grid-item.two-ninths-xs {
    width: 22.22222%; }
  .grid-item.two-tenth-xs,
  .grid-item.two-tenths-xs {
    width: 20%; }
  .grid-item.three-third-xs,
  .grid-item.three-thirds-xs {
    width: 100%; }
  .grid-item.three-fourth-xs,
  .grid-item.three-fourths-xs {
    width: 75%; }
  .grid-item.three-fifth-xs,
  .grid-item.three-fifths-xs {
    width: 60%; }
  .grid-item.three-sixth-xs,
  .grid-item.three-sixths-xs {
    width: 50%; }
  .grid-item.three-seventh-xs,
  .grid-item.three-sevenths-xs {
    width: 42.85714%; }
  .grid-item.three-eighth-xs,
  .grid-item.three-eighths-xs {
    width: 37.5%; }
  .grid-item.three-ninth-xs,
  .grid-item.three-ninths-xs {
    width: 33.33333%; }
  .grid-item.three-tenth-xs,
  .grid-item.three-tenths-xs {
    width: 30%; }
  .grid-item.four-fourth-xs,
  .grid-item.four-fourths-xs {
    width: 100%; }
  .grid-item.four-fifth-xs,
  .grid-item.four-fifths-xs {
    width: 80%; }
  .grid-item.four-sixth-xs,
  .grid-item.four-sixths-xs {
    width: 66.66667%; }
  .grid-item.four-seventh-xs,
  .grid-item.four-sevenths-xs {
    width: 57.14286%; }
  .grid-item.four-eighth-xs,
  .grid-item.four-eighths-xs {
    width: 50%; }
  .grid-item.four-ninth-xs,
  .grid-item.four-ninths-xs {
    width: 44.44444%; }
  .grid-item.four-tenth-xs,
  .grid-item.four-tenths-xs {
    width: 40%; }
  .grid-item.five-fifth-xs,
  .grid-item.five-fifths-xs {
    width: 100%; }
  .grid-item.five-sixth-xs,
  .grid-item.five-sixths-xs {
    width: 83.33333%; }
  .grid-item.five-seventh-xs,
  .grid-item.five-sevenths-xs {
    width: 71.42857%; }
  .grid-item.five-eighth-xs,
  .grid-item.five-eighths-xs {
    width: 62.5%; }
  .grid-item.five-ninth-xs,
  .grid-item.five-ninths-xs {
    width: 55.55556%; }
  .grid-item.five-tenth-xs,
  .grid-item.five-tenths-xs {
    width: 50%; }
  .grid-item.six-sixth-xs,
  .grid-item.six-sixths-xs {
    width: 100%; }
  .grid-item.six-seventh-xs,
  .grid-item.six-sevenths-xs {
    width: 85.71429%; }
  .grid-item.six-eighth-xs,
  .grid-item.six-eighths-xs {
    width: 75%; }
  .grid-item.six-ninth-xs,
  .grid-item.six-ninths-xs {
    width: 66.66667%; }
  .grid-item.six-tenth-xs,
  .grid-item.six-tenths-xs {
    width: 60%; }
  .grid-item.seven-seventh-xs,
  .grid-item.seven-sevenths-xs {
    width: 100%; }
  .grid-item.seven-eighth-xs,
  .grid-item.seven-eighths-xs {
    width: 87.5%; }
  .grid-item.seven-ninth-xs,
  .grid-item.seven-ninths-xs {
    width: 77.77778%; }
  .grid-item.seven-tenth-xs,
  .grid-item.seven-tenths-xs {
    width: 70%; }
  .grid-item.eight-eighth-xs,
  .grid-item.eight-eighths-xs {
    width: 100%; }
  .grid-item.eight-ninth-xs,
  .grid-item.eight-ninths-xs {
    width: 88.88889%; }
  .grid-item.eight-tenth-xs,
  .grid-item.eight-tenths-xs {
    width: 80%; }
  .grid-item.nine-ninth-xs,
  .grid-item.nine-ninths-xs {
    width: 100%; }
  .grid-item.nine-tenth-xs,
  .grid-item.nine-tenths-xs {
    width: 90%; }
  .grid-item.ten-tenth-xs,
  .grid-item.ten-tenths-xs {
    width: 100%; }
  /* ------------------------------------------------------------------------ * * Layout                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Slideshow                                                                *
\* ------------------------------------------------------------------------ */
  .slideshow-wrapper .slideshow-menu .menu-item {
    float: left;
    width: 50%; }
    .slideshow-wrapper .slideshow-menu .menu-item:nth-child(1), .slideshow-wrapper .slideshow-menu .menu-item:nth-child(3) {
      padding-right: 1px; }
  /* ------------------------------------------------------------------------ * * Module                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Align                                                                    *
\* ------------------------------------------------------------------------ */
  .aligncenter {
    margin: 0 auto 3.75em; }
  .alignleft {
    float: left;
    margin: 0em 3.75em 3.75em 0em; }
  .alignright {
    float: right;
    margin: 0em 0em 3.75em 3.75em; }
  /* ------------------------------------------------------------------------ * * Hiders                                                                   *
\* ------------------------------------------------------------------------ */
  .hide-xs {
    display: none !important; }
    .hide-xs.inline {
      display: none !important; }
  .show-xs {
    display: block !important; }
    .show-xs.inline {
      display: inline-block !important; }
  /* ------------------------------------------------------------------------ * * State                                                                    *
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 48rem) {
  /* ------------------------------------------------------------------------ * * Base                                                                     *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Grid                                                                     *
\* ------------------------------------------------------------------------ */
  .grid-item.full-s {
    width: 100%; }
  .grid-item.one-half-s {
    width: 49.71%; }
  .grid-item.one-third-s {
    width: 33.33333%; }
  .grid-item.one-fourth-s {
    width: 25%; }
  .grid-item.one-fifth-s {
    width: 20%; }
  .grid-item.one-sixth-s {
    width: 16.66667%; }
  .grid-item.one-seventh-s {
    width: 14.28571%; }
  .grid-item.one-eighth-s {
    width: 12.5%; }
  .grid-item.one-ninth-s {
    width: 11.11111%; }
  .grid-item.one-tenth-s {
    width: 10%; }
  .grid-item.two-half-s,
  .grid-item.two-halfs-s,
  .grid-item.two-halves-s {
    width: 66.66667%; }
  .grid-item.two-third-s,
  .grid-item.two-thirds-s {
    width: 66.66667%; }
  .grid-item.two-fourth-s,
  .grid-item.two-fourths-s {
    width: 50%; }
  .grid-item.two-fifth-s,
  .grid-item.two-fifths-s {
    width: 40%; }
  .grid-item.two-sixth-s,
  .grid-item.two-sixths-s {
    width: 33.33333%; }
  .grid-item.two-seventh-s,
  .grid-item.two-sevenths-s {
    width: 28.57143%; }
  .grid-item.two-eighth-s,
  .grid-item.two-eighths-s {
    width: 25%; }
  .grid-item.two-ninth-s,
  .grid-item.two-ninths-s {
    width: 22.22222%; }
  .grid-item.two-tenth-s,
  .grid-item.two-tenths-s {
    width: 20%; }
  .grid-item.three-third-s,
  .grid-item.three-thirds-s {
    width: 100%; }
  .grid-item.three-fourth-s,
  .grid-item.three-fourths-s {
    width: 75%; }
  .grid-item.three-fifth-s,
  .grid-item.three-fifths-s {
    width: 60%; }
  .grid-item.three-sixth-s,
  .grid-item.three-sixths-s {
    width: 50%; }
  .grid-item.three-seventh-s,
  .grid-item.three-sevenths-s {
    width: 42.85714%; }
  .grid-item.three-eighth-s,
  .grid-item.three-eighths-s {
    width: 37.5%; }
  .grid-item.three-ninth-s,
  .grid-item.three-ninths-s {
    width: 33.33333%; }
  .grid-item.three-tenth-s,
  .grid-item.three-tenths-s {
    width: 30%; }
  .grid-item.four-fourth-s,
  .grid-item.four-fourths-s {
    width: 100%; }
  .grid-item.four-fifth-s,
  .grid-item.four-fifths-s {
    width: 80%; }
  .grid-item.four-sixth-s,
  .grid-item.four-sixths-s {
    width: 66.66667%; }
  .grid-item.four-seventh-s,
  .grid-item.four-sevenths-s {
    width: 57.14286%; }
  .grid-item.four-eighth-s,
  .grid-item.four-eighths-s {
    width: 50%; }
  .grid-item.four-ninth-s,
  .grid-item.four-ninths-s {
    width: 44.44444%; }
  .grid-item.four-tenth-s,
  .grid-item.four-tenths-s {
    width: 40%; }
  .grid-item.five-fifth-s,
  .grid-item.five-fifths-s {
    width: 100%; }
  .grid-item.five-sixth-s,
  .grid-item.five-sixths-s {
    width: 83.33333%; }
  .grid-item.five-seventh-s,
  .grid-item.five-sevenths-s {
    width: 71.42857%; }
  .grid-item.five-eighth-s,
  .grid-item.five-eighths-s {
    width: 62.5%; }
  .grid-item.five-ninth-s,
  .grid-item.five-ninths-s {
    width: 55.55556%; }
  .grid-item.five-tenth-s,
  .grid-item.five-tenths-s {
    width: 50%; }
  .grid-item.six-sixth-s,
  .grid-item.six-sixths-s {
    width: 100%; }
  .grid-item.six-seventh-s,
  .grid-item.six-sevenths-s {
    width: 85.71429%; }
  .grid-item.six-eighth-s,
  .grid-item.six-eighths-s {
    width: 75%; }
  .grid-item.six-ninth-s,
  .grid-item.six-ninths-s {
    width: 66.66667%; }
  .grid-item.six-tenth-s,
  .grid-item.six-tenths-s {
    width: 60%; }
  .grid-item.seven-seventh-s,
  .grid-item.seven-sevenths-s {
    width: 100%; }
  .grid-item.seven-eighth-s,
  .grid-item.seven-eighths-s {
    width: 87.5%; }
  .grid-item.seven-ninth-s,
  .grid-item.seven-ninths-s {
    width: 77.77778%; }
  .grid-item.seven-tenth-s,
  .grid-item.seven-tenths-s {
    width: 70%; }
  .grid-item.eight-eighth-s,
  .grid-item.eight-eighths-s {
    width: 100%; }
  .grid-item.eight-ninth-s,
  .grid-item.eight-ninths-s {
    width: 88.88889%; }
  .grid-item.eight-tenth-s,
  .grid-item.eight-tenths-s {
    width: 80%; }
  .grid-item.nine-ninth-s,
  .grid-item.nine-ninths-s {
    width: 100%; }
  .grid-item.nine-tenth-s,
  .grid-item.nine-tenths-s {
    width: 90%; }
  .grid-item.ten-tenth-s,
  .grid-item.ten-tenths-s {
    width: 100%; }
  /* ------------------------------------------------------------------------ * * Text Elements                                                            *
\* ------------------------------------------------------------------------ */
  ::selection {
    background: #FF843D;
    color: #FFFFFF; }
  .hdg.hdg1,
  .hdg.hdg2 {
    font-size: 24px;
    font-size: 1.5rem; }
  .hdg.hdg3,
  .hdg.hdg4,
  .hdg.hdg5,
  .hdg.hdg6 {
    font-size: 20px;
    font-size: 1.25rem; }
  .txt {
    font-size: 16px;
    font-size: 1rem; }
  /* ------------------------------------------------------------------------ * * User Content                                                             *
\* ------------------------------------------------------------------------ */
  .user-content h1,
  .user-content h2 {
    font-size: 24px;
    font-size: 1.5rem; }
  .user-content h3,
  .user-content h4,
  .user-content h5,
  .user-content h6 {
    font-size: 20px;
    font-size: 1.25rem; }
  .user-content menu,
  .user-content ol,
  .user-content p,
  .user-content table,
  .user-content ul {
    font-size: 16px;
    font-size: 1rem; }
  ul.two-column {
    overflow: hidden; }
  ul.two-column li {
    float: left;
    width: 50%; }
  .user-content table.blades {
    width: auto; }
    .user-content table.blades > tbody {
      width: auto;
      display: table-row-group; }
  .user-content table {
    display: table;
    overflow: visible;
    padding: 0; }
    .user-content table.stack thead {
      display: table-header-group; }
    .user-content table.stack tbody {
      display: table-row-group; }
    .user-content table.stack tfoot {
      display: table-footer-group; }
    .user-content table.stack tr {
      display: table-row; }
    .user-content table.stack th,
    .user-content table.stack td {
      display: table-cell; }
    .user-content table.stack th:not(:first-child),
    .user-content table.stack td:not(:first-child) {
      padding-top: 1em; }
    .user-content table.stack th:not(:last-child),
    .user-content table.stack td:not(:last-child) {
      border-bottom-width: 1px;
      padding-bottom: 1em; }
  .user-content input,
  .user-content label,
  .user-content .select,
  .user-content select,
  .user-content textarea {
    font-size: 16px;
    font-size: 1rem; }
  .user-content button,
  .user-content input[type=button],
  .user-content input[type=reset],
  .user-content input[type=submit],
  .user-content .button {
    font-size: 18px;
    font-size: 1.125rem; }
  /* ------------------------------------------------------------------------ * * Layout                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Header                                                                   *
\* ------------------------------------------------------------------------ */
  .header {
    position: relative; }
  .header .header-left:after {
    border-bottom: 4.5625em solid transparent;
    border-right: 4.5625em solid #000000;
    border-top: 4.5625em solid transparent;
    margin-top: -4.5625em; }
  .header .header-right {
    position: static;
    width: 29.6875em; }
  .header .logo {
    padding: 2.125em 1.5em;
    width: 13.75em; }
  .header .hdg.hdg4 {
    color: #FFFFFF;
    float: left;
    font-size: 1.25em;
    margin: 0.2em 1.3em 0.15em 0em; }
  .header .hdg.hdg4 a {
    color: #FFFFFF;
    text-decoration: none;
    transition: color 0.15s; }
    .header .hdg.hdg4 a:hover {
      color: #D5D5D5; }
  .header .search-form {
    flex: 1 1 auto;
    float: left;
    font-size: 0.75em;
    margin: 0em 0.6875em;
    width: 13.75em; }
    .flexbox .header .search-form {
      float: none; }
    .flexbox .header .search-form,
    .flexboxlegacy .header .search-form {
      width: 0; }
  .header .menu-wrapper.social {
    padding: 0.875em 1.625em 0.875em 0.9375em; }
  .header .menu-wrapper.secondary {
    display: flex;
    padding: 0em 1.125em 0em 0.375em; }
  .header .menu-wrapper.primary {
    padding: 0em 0.75em 0em 0em;
    position: static;
    width: 100%; }
  .header .menu-wrapper.social .menu-list {
    float: left; }
  .header .menu-wrapper.secondary .menu-list {
    color: #F37022;
    flex: none;
    float: left;
    font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
    font-size: 0.8125em;
    font-weight: 400; }
    .flexbox .header .menu-wrapper.secondary .menu-list {
      float: none; }
  .header .menu-wrapper.primary .menu-list {
    color: #F37022;
    font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
    font-size: 1.01563em;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%; }
    .header .menu-wrapper.primary .menu-list:not(.sub-menu) {
      position: static; }
  .header .menu-wrapper.primary .menu-list.sub-menu {
    background: #000000;
    font-size: 1.3em;
    padding: 1.15385em 0em;
    text-align: left; }
  .header .menu-wrapper.primary .menu-list.sub-menu .menu-list.sub-menu {
    background: none;
    font-size: 0.53846em;
    font-weight: 400;
    line-height: 1.42857em;
    padding: 0;
    width: auto; }
  .header .menu-wrapper.primary .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu {
    font-size: 1em;
    line-height: 1.42857em;
    text-transform: none; }
  .header .menu-wrapper.primary .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu {
    padding-left: 0.71429em; }
  .header .menu-wrapper.social .menu-item:not(:last-child) {
    margin: 0em 0.75em 0em 0em; }
  .header .menu-wrapper.secondary .menu-item a {
    color: #F37022;
    padding: 0.75em 0.6875em 0.6875em;
    text-decoration: none;
    transition: color 0.15s; }
    .header .menu-wrapper.secondary .menu-item a:hover {
      color: #D5D5D5; }
  .header .menu-wrapper.primary .menu-item a {
    color: #F37022;
    padding: 1.1em 0.9em;
    text-decoration: none;
    transition: color 0.15s; }
    .header .menu-wrapper.primary .menu-item a:hover {
      color: #D5D5D5; }
  .header .menu-wrapper.primary .menu-list.sub-menu .menu-item a {
    padding: 0.07692em 1.07692em; }
  .header .menu-wrapper.primary .menu-list.sub-menu .menu-list.sub-menu .menu-item a {
    padding: 0em 2em; }
  .header .menu-wrapper.primary .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu .menu-item a {
    color: #D5D5D5; }
    .header .menu-wrapper.primary .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu .menu-item a:hover {
      color: #F37022; }
    .header .menu-wrapper.primary .menu-list.sub-menu .menu-list.sub-menu .menu-list.sub-menu .menu-item a:before {
      content: "- "; }
  /* ------------------------------------------------------------------------ * * Content                                                                  *
\* ------------------------------------------------------------------------ */
  .content-wrapper:nth-child(2) {
    padding-top: 80px;
    padding-top: 5rem; }
  .content {
    position: relative; }
  .content .breadcrumb {
    bottom: 100%;
    filter: alpha(opacity=45);
    left: 0;
    opacity: 0.45;
    position: absolute;
    right: 0; }
  .content .breadcrumb p {
    border-bottom: 1px solid #b6b6b6;
    color: #2A2A2A;
    font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
    font-size: 0.8125em;
    font-weight: 400;
    line-height: normal;
    margin: 0em 0em 2.46154em;
    text-transform: uppercase; }
  .content .breadcrumb a,
  .content .breadcrumb .breadcrumb_last {
    color: #2A2A2A;
    display: inline-block;
    text-decoration: none;
    padding: 1.07692em 0em;
    transition: color 0.15s; }
    .content .breadcrumb a:hover,
    .content .breadcrumb .breadcrumb_last:hover {
      color: #111111; }
  .single-product .post {
    *zoom: 1;
    display: block; }
    .single-product .post:before, .single-product .post:after {
      content: "";
      display: table; }
    .single-product .post:after {
      clear: both; }
  .single-product .post .product-left {
    float: left;
    width: 61.25%; }
  .single-product .post .product-right {
    float: right;
    padding-left: 50px;
    padding-left: 3.125rem;
    width: 38.75%; }
  /* ------------------------------------------------------------------------ * * Footer                                                                   *
\* ------------------------------------------------------------------------ */
  .footer-wrapper {
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%; }
  .footer {
    *zoom: 1; }
    .footer:before, .footer:after {
      content: "";
      display: table; }
    .footer:after {
      clear: both; }
  .footer-left {
    background: #000000;
    float: left;
    padding: 4.75em 0em 3.375em;
    position: relative;
    width: 50%; }
    .footer-left:before {
      background: #000000;
      bottom: 0;
      content: "\0020";
      display: block;
      position: absolute;
      right: 100%;
      top: 0;
      width: 125em; }
    .footer-left:after {
      border-bottom: 4.6875em solid transparent;
      border-right: 4.6875em solid #F37022;
      content: "\0020";
      display: block;
      position: absolute;
      right: 0;
      top: 0; }
  .footer-right {
    float: right;
    padding: 1.875em 0em 1.875em 4.0625em;
    width: 50%; }
  .footer .sitemap {
    *zoom: 1; }
    .footer .sitemap:before, .footer .sitemap:after {
      content: "";
      display: table; }
    .footer .sitemap:after {
      clear: both; }
  .footer .sitemap .menu-list {
    color: #F37022;
    float: left;
    font-family: "proxima-nova", "Open Sans", "Arial", "Helvetica", sans-serif;
    font-size: 1.25em;
    font-weight: 700;
    line-height: 1.32em;
    margin: 0;
    padding: 0em 1em 0em 0em;
    position: relative;
    text-transform: uppercase;
    width: 50%; }
    .footer .sitemap .menu-list:last-child {
      float: right; }
  .footer .sitemap .menu-list.sub-menu,
  .footer .sitemap .menu-item:hover .menu-list.sub-menu {
    color: #FFFFFF;
    float: none;
    font-size: 0.6em;
    font-weight: 400;
    line-height: 1.73333em;
    margin: 0em 0em 0.83333em;
    max-height: none;
    padding: 0;
    width: 100%; }
  .footer .sitemap .menu-item a {
    color: #F37022;
    padding: 0;
    text-decoration: none;
    transition: color 0.15s; }
    .footer .sitemap .menu-item a:hover {
      color: #f68f52; }
  .footer .sitemap .menu-list.sub-menu .menu-item a {
    color: #FFFFFF; }
    .footer .sitemap .menu-list.sub-menu .menu-item a:hover {
      color: #e6e6e6; }
  .footer .logo {
    margin: 0em 0em 1em -2.5em;
    width: 17.5em; }
  .footer .txt.txtp {
    font-size: 0.875em;
    line-height: 1.48571em;
    text-align: left; }
  /* ------------------------------------------------------------------------ * * Module                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Hiders                                                                   *
\* ------------------------------------------------------------------------ */
  .hide-s {
    display: none !important; }
    .hide-s.inline {
      display: none !important; }
  .show-s {
    display: block !important; }
    .show-s.inline {
      display: inline-block !important; }
  /* ------------------------------------------------------------------------ * * Mini Product                                                             *
\* ------------------------------------------------------------------------ */
  .mini-product .hdg.hdg1 {
    font-size: 1.5em; }
  .mini-product .hdg.hdg2 {
    font-size: 1.25em; }
  /* ------------------------------------------------------------------------ * * State                                                                    *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Page                                                                     *
\* ------------------------------------------------------------------------ */
  html.is-navopen,
  .is-navopen body {
    height: auto;
    width: auto; }
  .is-navopen .page-wrapper {
    box-shadow: none;
    left: auto; } }

@media screen and (min-width: 53.3125rem) {
  /* ------------------------------------------------------------------------ * * Base                                                                     *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Grid                                                                     *
\* ------------------------------------------------------------------------ */
  .grid-item.full-m {
    width: 100%; }
  .grid-item.one-half-m {
    width: 50%; }
  .grid-item.one-third-m {
    width: 33.33333%; }
  .grid-item.one-fourth-m {
    width: 25%; }
  .grid-item.one-fifth-m {
    width: 20%; }
  .grid-item.one-sixth-m {
    width: 16.66667%; }
  .grid-item.one-seventh-m {
    width: 14.28571%; }
  .grid-item.one-eighth-m {
    width: 12.5%; }
  .grid-item.one-ninth-m {
    width: 11.11111%; }
  .grid-item.one-tenth-m {
    width: 10%; }
  .grid-item.two-half-m,
  .grid-item.two-halfs-m,
  .grid-item.two-halves-m {
    width: 66.66667%; }
  .grid-item.two-third-m,
  .grid-item.two-thirds-m {
    width: 66.66667%; }
  .grid-item.two-fourth-m,
  .grid-item.two-fourths-m {
    width: 50%; }
  .grid-item.two-fifth-m,
  .grid-item.two-fifths-m {
    width: 40%; }
  .grid-item.two-sixth-m,
  .grid-item.two-sixths-m {
    width: 33.33333%; }
  .grid-item.two-seventh-m,
  .grid-item.two-sevenths-m {
    width: 28.57143%; }
  .grid-item.two-eighth-m,
  .grid-item.two-eighths-m {
    width: 25%; }
  .grid-item.two-ninth-m,
  .grid-item.two-ninths-m {
    width: 22.22222%; }
  .grid-item.two-tenth-m,
  .grid-item.two-tenths-m {
    width: 20%; }
  .grid-item.three-third-m,
  .grid-item.three-thirds-m {
    width: 100%; }
  .grid-item.three-fourth-m,
  .grid-item.three-fourths-m {
    width: 75%; }
  .grid-item.three-fifth-m,
  .grid-item.three-fifths-m {
    width: 60%; }
  .grid-item.three-sixth-m,
  .grid-item.three-sixths-m {
    width: 50%; }
  .grid-item.three-seventh-m,
  .grid-item.three-sevenths-m {
    width: 42.85714%; }
  .grid-item.three-eighth-m,
  .grid-item.three-eighths-m {
    width: 37.5%; }
  .grid-item.three-ninth-m,
  .grid-item.three-ninths-m {
    width: 33.33333%; }
  .grid-item.three-tenth-m,
  .grid-item.three-tenths-m {
    width: 30%; }
  .grid-item.four-fourth-m,
  .grid-item.four-fourths-m {
    width: 100%; }
  .grid-item.four-fifth-m,
  .grid-item.four-fifths-m {
    width: 80%; }
  .grid-item.four-sixth-m,
  .grid-item.four-sixths-m {
    width: 66.66667%; }
  .grid-item.four-seventh-m,
  .grid-item.four-sevenths-m {
    width: 57.14286%; }
  .grid-item.four-eighth-m,
  .grid-item.four-eighths-m {
    width: 50%; }
  .grid-item.four-ninth-m,
  .grid-item.four-ninths-m {
    width: 44.44444%; }
  .grid-item.four-tenth-m,
  .grid-item.four-tenths-m {
    width: 40%; }
  .grid-item.five-fifth-m,
  .grid-item.five-fifths-m {
    width: 100%; }
  .grid-item.five-sixth-m,
  .grid-item.five-sixths-m {
    width: 83.33333%; }
  .grid-item.five-seventh-m,
  .grid-item.five-sevenths-m {
    width: 71.42857%; }
  .grid-item.five-eighth-m,
  .grid-item.five-eighths-m {
    width: 62.5%; }
  .grid-item.five-ninth-m,
  .grid-item.five-ninths-m {
    width: 55.55556%; }
  .grid-item.five-tenth-m,
  .grid-item.five-tenths-m {
    width: 50%; }
  .grid-item.six-sixth-m,
  .grid-item.six-sixths-m {
    width: 100%; }
  .grid-item.six-seventh-m,
  .grid-item.six-sevenths-m {
    width: 85.71429%; }
  .grid-item.six-eighth-m,
  .grid-item.six-eighths-m {
    width: 75%; }
  .grid-item.six-ninth-m,
  .grid-item.six-ninths-m {
    width: 66.66667%; }
  .grid-item.six-tenth-m,
  .grid-item.six-tenths-m {
    width: 60%; }
  .grid-item.seven-seventh-m,
  .grid-item.seven-sevenths-m {
    width: 100%; }
  .grid-item.seven-eighth-m,
  .grid-item.seven-eighths-m {
    width: 87.5%; }
  .grid-item.seven-ninth-m,
  .grid-item.seven-ninths-m {
    width: 77.77778%; }
  .grid-item.seven-tenth-m,
  .grid-item.seven-tenths-m {
    width: 70%; }
  .grid-item.eight-eighth-m,
  .grid-item.eight-eighths-m {
    width: 100%; }
  .grid-item.eight-ninth-m,
  .grid-item.eight-ninths-m {
    width: 88.88889%; }
  .grid-item.eight-tenth-m,
  .grid-item.eight-tenths-m {
    width: 80%; }
  .grid-item.nine-ninth-m,
  .grid-item.nine-ninths-m {
    width: 100%; }
  .grid-item.nine-tenth-m,
  .grid-item.nine-tenths-m {
    width: 90%; }
  .grid-item.ten-tenth-m,
  .grid-item.ten-tenths-m {
    width: 100%; }
  /* ------------------------------------------------------------------------ * * Layout                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Slideshow                                                                *
\* ------------------------------------------------------------------------ */
  .slideshow {
    margin-bottom: 0; }
  .slideshow-wrapper .slideshow-menu .menu-list {
    display: flex; }
  .slideshow-wrapper .slideshow-menu .menu-item {
    display: flex;
    flex: 1 1 auto;
    float: none;
    margin: 0 !important;
    padding: 0 !important;
    width: 0; }
    .modernizr-no-flexbox.modernizr-no-flexboxlegacy .slideshow-wrapper .slideshow-menu .menu-item {
      float: left;
      width: 25%; }
  .slideshow-wrapper .slideshow-menu .menu-item .thumbnail-text {
    align-content: center;
    align-items: center;
    display: flex;
    padding-bottom: 1.14286em;
    padding-top: 1.21429em;
    width: 100%; }
  .slideshow-wrapper .slideshow-menu .menu-item.alt .thumbnail-text:after {
    border-bottom-width: 2.42857em;
    border-right-width: 2.42857em;
    border-top-width: 2.42857em;
    margin-top: -2.42857em; }
  /* ------------------------------------------------------------------------ * * Content                                                                  *
\* ------------------------------------------------------------------------ */
  .content-wrapper {
    padding-bottom: 60px;
    padding-bottom: 3.75rem;
    padding-top: 60px;
    padding-top: 3.75rem; }
  /* ------------------------------------------------------------------------ * * Footer                                                                   *
\* ------------------------------------------------------------------------ */
  .footer-left {
    width: 60%; }
  .footer-right {
    width: 40%; }
  /* ------------------------------------------------------------------------ * * Module                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Hiders                                                                   *
\* ------------------------------------------------------------------------ */
  .hide-m {
    display: none !important; }
    .hide-m.inline {
      display: none !important; }
  .show-m {
    display: block !important; }
    .show-m.inline {
      display: inline-block !important; }
  /* ------------------------------------------------------------------------ * * Swiper                                                                   *
\* ------------------------------------------------------------------------ */
  .swiper-caption {
    right: 0;
    padding: 1.5em 1.25em 2.625em;
    position: absolute;
    top: 25%;
    transform: translateY(-50%);
    width: 23.75em; }
  .swiper-caption .hdg.hdg2 {
    font-size: 1.25em; }
  .swiper-caption .txt {
    font-size: 1em; }
  .swiper-caption .btn {
    bottom: auto;
    display: inline-block;
    font-size: 1em;
    left: 0.93502em;
    top: 100%;
    transform: translateY(-50%);
    width: auto; }
  /* ------------------------------------------------------------------------ * * State                                                                    *
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 60rem) {
  /* ------------------------------------------------------------------------ * * Base                                                                     *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Grid                                                                     *
\* ------------------------------------------------------------------------ */
  .grid-item.full-l {
    width: 100%; }
  .grid-item.one-half-l {
    width: 50%; }
  .grid-item.one-third-l {
    width: 33.33333%; }
  .grid-item.one-fourth-l {
    width: 25%; }
  .grid-item.one-fifth-l {
    width: 20%; }
  .grid-item.one-sixth-l {
    width: 16.66667%; }
  .grid-item.one-seventh-l {
    width: 14.28571%; }
  .grid-item.one-eighth-l {
    width: 12.5%; }
  .grid-item.one-ninth-l {
    width: 11.11111%; }
  .grid-item.one-tenth-l {
    width: 10%; }
  .grid-item.two-half-l,
  .grid-item.two-halfs-l,
  .grid-item.two-halves-l {
    width: 66.66667%; }
  .grid-item.two-third-l,
  .grid-item.two-thirds-l {
    width: 66.66667%; }
  .grid-item.two-fourth-l,
  .grid-item.two-fourths-l {
    width: 50%; }
  .grid-item.two-fifth-l,
  .grid-item.two-fifths-l {
    width: 40%; }
  .grid-item.two-sixth-l,
  .grid-item.two-sixths-l {
    width: 33.33333%; }
  .grid-item.two-seventh-l,
  .grid-item.two-sevenths-l {
    width: 28.57143%; }
  .grid-item.two-eighth-l,
  .grid-item.two-eighths-l {
    width: 25%; }
  .grid-item.two-ninth-l,
  .grid-item.two-ninths-l {
    width: 22.22222%; }
  .grid-item.two-tenth-l,
  .grid-item.two-tenths-l {
    width: 20%; }
  .grid-item.three-third-l,
  .grid-item.three-thirds-l {
    width: 100%; }
  .grid-item.three-fourth-l,
  .grid-item.three-fourths-l {
    width: 75%; }
  .grid-item.three-fifth-l,
  .grid-item.three-fifths-l {
    width: 60%; }
  .grid-item.three-sixth-l,
  .grid-item.three-sixths-l {
    width: 50%; }
  .grid-item.three-seventh-l,
  .grid-item.three-sevenths-l {
    width: 42.85714%; }
  .grid-item.three-eighth-l,
  .grid-item.three-eighths-l {
    width: 37.5%; }
  .grid-item.three-ninth-l,
  .grid-item.three-ninths-l {
    width: 33.33333%; }
  .grid-item.three-tenth-l,
  .grid-item.three-tenths-l {
    width: 30%; }
  .grid-item.four-fourth-l,
  .grid-item.four-fourths-l {
    width: 100%; }
  .grid-item.four-fifth-l,
  .grid-item.four-fifths-l {
    width: 80%; }
  .grid-item.four-sixth-l,
  .grid-item.four-sixths-l {
    width: 66.66667%; }
  .grid-item.four-seventh-l,
  .grid-item.four-sevenths-l {
    width: 57.14286%; }
  .grid-item.four-eighth-l,
  .grid-item.four-eighths-l {
    width: 50%; }
  .grid-item.four-ninth-l,
  .grid-item.four-ninths-l {
    width: 44.44444%; }
  .grid-item.four-tenth-l,
  .grid-item.four-tenths-l {
    width: 40%; }
  .grid-item.five-fifth-l,
  .grid-item.five-fifths-l {
    width: 100%; }
  .grid-item.five-sixth-l,
  .grid-item.five-sixths-l {
    width: 83.33333%; }
  .grid-item.five-seventh-l,
  .grid-item.five-sevenths-l {
    width: 71.42857%; }
  .grid-item.five-eighth-l,
  .grid-item.five-eighths-l {
    width: 62.5%; }
  .grid-item.five-ninth-l,
  .grid-item.five-ninths-l {
    width: 55.55556%; }
  .grid-item.five-tenth-l,
  .grid-item.five-tenths-l {
    width: 50%; }
  .grid-item.six-sixth-l,
  .grid-item.six-sixths-l {
    width: 100%; }
  .grid-item.six-seventh-l,
  .grid-item.six-sevenths-l {
    width: 85.71429%; }
  .grid-item.six-eighth-l,
  .grid-item.six-eighths-l {
    width: 75%; }
  .grid-item.six-ninth-l,
  .grid-item.six-ninths-l {
    width: 66.66667%; }
  .grid-item.six-tenth-l,
  .grid-item.six-tenths-l {
    width: 60%; }
  .grid-item.seven-seventh-l,
  .grid-item.seven-sevenths-l {
    width: 100%; }
  .grid-item.seven-eighth-l,
  .grid-item.seven-eighths-l {
    width: 87.5%; }
  .grid-item.seven-ninth-l,
  .grid-item.seven-ninths-l {
    width: 77.77778%; }
  .grid-item.seven-tenth-l,
  .grid-item.seven-tenths-l {
    width: 70%; }
  .grid-item.eight-eighth-l,
  .grid-item.eight-eighths-l {
    width: 100%; }
  .grid-item.eight-ninth-l,
  .grid-item.eight-ninths-l {
    width: 88.88889%; }
  .grid-item.eight-tenth-l,
  .grid-item.eight-tenths-l {
    width: 80%; }
  .grid-item.nine-ninth-l,
  .grid-item.nine-ninths-l {
    width: 100%; }
  .grid-item.nine-tenth-l,
  .grid-item.nine-tenths-l {
    width: 90%; }
  .grid-item.ten-tenth-l,
  .grid-item.ten-tenths-l {
    width: 100%; }
  /* ------------------------------------------------------------------------ * * Layout                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Header                                                                   *
\* ------------------------------------------------------------------------ */
  .header .header-left:after {
    border-bottom: 5.4375em solid transparent;
    border-right: 5.4375em solid #000000;
    border-top: 5.4375em solid transparent;
    margin-top: -5.4375em; }
  .header .header-right {
    width: 40.375em; }
  .header .logo {
    padding: 2.84375em 1.5em;
    width: 14.1875em; }
  .header .hdg.hdg4 {
    margin: 0.2em 1.2em 0.15em 0em; }
  .header .search-form {
    font-size: 1em;
    margin: 0em 0.5em 0em 0.375em; }
  .header .menu-wrapper.social {
    padding: 0.875em 1.625em 0.875em 1.875em; }
  .header .menu-wrapper.secondary {
    padding: 0em 1.125em 0em 0.9375em; }
  .header .menu-wrapper.primary {
    padding: 0em 0.125em 0em 0em; }
  .header .menu-wrapper.secondary .menu-list {
    font-size: 1em; }
  .header .menu-wrapper.primary .menu-list {
    font-size: 1.25em; }
  .header .menu-wrapper.social .menu-item:not(:last-child) {
    margin: 0em 0.5em 0em 0em; }
  .header .menu-wrapper.secondary .menu-item a {
    padding: 0.8125em 0.9375em 0.75em; }
  .header .menu-wrapper.primary .menu-item a {
    padding: 1.1em 1.45em; }
  /* ------------------------------------------------------------------------ * * Footer                                                                   *
\* ------------------------------------------------------------------------ */
  .footer-left {
    width: 70%; }
  .footer-right {
    width: 30%; }
  .footer .logo {
    width: 15.625em; }
  /* ------------------------------------------------------------------------ * * Module                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Hiders                                                                   *
\* ------------------------------------------------------------------------ */
  .hide-l {
    display: none !important; }
    .hide-l.inline {
      display: none !important; }
  .show-l {
    display: block !important; }
    .show-l.inline {
      display: inline-block !important; }
  /* ------------------------------------------------------------------------ * * State                                                                    *
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 64rem) {
  /* ------------------------------------------------------------------------ * * Base                                                                     *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Grid                                                                     *
\* ------------------------------------------------------------------------ */
  .grid-item.full-xl {
    width: 100%; }
  .grid-item.one-half-xl {
    width: 50%; }
  .grid-item.one-third-xl {
    width: 33.33333%; }
  .grid-item.one-fourth-xl {
    width: 25%; }
  .grid-item.one-fifth-xl {
    width: 20%; }
  .grid-item.one-sixth-xl {
    width: 16.66667%; }
  .grid-item.one-seventh-xl {
    width: 14.28571%; }
  .grid-item.one-eighth-xl {
    width: 12.5%; }
  .grid-item.one-ninth-xl {
    width: 11.11111%; }
  .grid-item.one-tenth-xl {
    width: 10%; }
  .grid-item.two-half-xl,
  .grid-item.two-halfs-xl,
  .grid-item.two-halves-xl {
    width: 66.66667%; }
  .grid-item.two-third-xl,
  .grid-item.two-thirds-xl {
    width: 66.66667%; }
  .grid-item.two-fourth-xl,
  .grid-item.two-fourths-xl {
    width: 50%; }
  .grid-item.two-fifth-xl,
  .grid-item.two-fifths-xl {
    width: 40%; }
  .grid-item.two-sixth-xl,
  .grid-item.two-sixths-xl {
    width: 33.33333%; }
  .grid-item.two-seventh-xl,
  .grid-item.two-sevenths-xl {
    width: 28.57143%; }
  .grid-item.two-eighth-xl,
  .grid-item.two-eighths-xl {
    width: 25%; }
  .grid-item.two-ninth-xl,
  .grid-item.two-ninths-xl {
    width: 22.22222%; }
  .grid-item.two-tenth-xl,
  .grid-item.two-tenths-xl {
    width: 20%; }
  .grid-item.three-third-xl,
  .grid-item.three-thirds-xl {
    width: 100%; }
  .grid-item.three-fourth-xl,
  .grid-item.three-fourths-xl {
    width: 75%; }
  .grid-item.three-fifth-xl,
  .grid-item.three-fifths-xl {
    width: 60%; }
  .grid-item.three-sixth-xl,
  .grid-item.three-sixths-xl {
    width: 50%; }
  .grid-item.three-seventh-xl,
  .grid-item.three-sevenths-xl {
    width: 42.85714%; }
  .grid-item.three-eighth-xl,
  .grid-item.three-eighths-xl {
    width: 37.5%; }
  .grid-item.three-ninth-xl,
  .grid-item.three-ninths-xl {
    width: 33.33333%; }
  .grid-item.three-tenth-xl,
  .grid-item.three-tenths-xl {
    width: 30%; }
  .grid-item.four-fourth-xl,
  .grid-item.four-fourths-xl {
    width: 100%; }
  .grid-item.four-fifth-xl,
  .grid-item.four-fifths-xl {
    width: 80%; }
  .grid-item.four-sixth-xl,
  .grid-item.four-sixths-xl {
    width: 66.66667%; }
  .grid-item.four-seventh-xl,
  .grid-item.four-sevenths-xl {
    width: 57.14286%; }
  .grid-item.four-eighth-xl,
  .grid-item.four-eighths-xl {
    width: 50%; }
  .grid-item.four-ninth-xl,
  .grid-item.four-ninths-xl {
    width: 44.44444%; }
  .grid-item.four-tenth-xl,
  .grid-item.four-tenths-xl {
    width: 40%; }
  .grid-item.five-fifth-xl,
  .grid-item.five-fifths-xl {
    width: 100%; }
  .grid-item.five-sixth-xl,
  .grid-item.five-sixths-xl {
    width: 83.33333%; }
  .grid-item.five-seventh-xl,
  .grid-item.five-sevenths-xl {
    width: 71.42857%; }
  .grid-item.five-eighth-xl,
  .grid-item.five-eighths-xl {
    width: 62.5%; }
  .grid-item.five-ninth-xl,
  .grid-item.five-ninths-xl {
    width: 55.55556%; }
  .grid-item.five-tenth-xl,
  .grid-item.five-tenths-xl {
    width: 50%; }
  .grid-item.six-sixth-xl,
  .grid-item.six-sixths-xl {
    width: 100%; }
  .grid-item.six-seventh-xl,
  .grid-item.six-sevenths-xl {
    width: 85.71429%; }
  .grid-item.six-eighth-xl,
  .grid-item.six-eighths-xl {
    width: 75%; }
  .grid-item.six-ninth-xl,
  .grid-item.six-ninths-xl {
    width: 66.66667%; }
  .grid-item.six-tenth-xl,
  .grid-item.six-tenths-xl {
    width: 60%; }
  .grid-item.seven-seventh-xl,
  .grid-item.seven-sevenths-xl {
    width: 100%; }
  .grid-item.seven-eighth-xl,
  .grid-item.seven-eighths-xl {
    width: 87.5%; }
  .grid-item.seven-ninth-xl,
  .grid-item.seven-ninths-xl {
    width: 77.77778%; }
  .grid-item.seven-tenth-xl,
  .grid-item.seven-tenths-xl {
    width: 70%; }
  .grid-item.eight-eighth-xl,
  .grid-item.eight-eighths-xl {
    width: 100%; }
  .grid-item.eight-ninth-xl,
  .grid-item.eight-ninths-xl {
    width: 88.88889%; }
  .grid-item.eight-tenth-xl,
  .grid-item.eight-tenths-xl {
    width: 80%; }
  .grid-item.nine-ninth-xl,
  .grid-item.nine-ninths-xl {
    width: 100%; }
  .grid-item.nine-tenth-xl,
  .grid-item.nine-tenths-xl {
    width: 90%; }
  .grid-item.ten-tenth-xl,
  .grid-item.ten-tenths-xl {
    width: 100%; }
  /* ------------------------------------------------------------------------ * * Layout                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Header                                                                   *
\* ------------------------------------------------------------------------ */
  .header-wrapper {
    position: relative;
    width: 100%; }
  .header-left:before {
    background: #F37022;
    bottom: 0;
    content: "\0020";
    display: block;
    right: 100%;
    position: absolute;
    top: 0;
    width: 125em; }
  .header .logo {
    padding: 1.91875em 1.5em;
    width: 18.25em; }
  /* ------------------------------------------------------------------------ * * Slideshow                                                                *
\* ------------------------------------------------------------------------ */
  .slideshow-wrapper .slideshow-menu .menu-list {
    font-size: 1.14286em; }
  /* ------------------------------------------------------------------------ * * Footer                                                                   *
\* ------------------------------------------------------------------------ */
  .footer-left:after {
    border-bottom-width: 9.375em;
    border-right-width: 9.375em; }
  .footer .sitemap > .menu-list {
    *zoom: 1;
    width: 66.66667%; }
    .footer .sitemap > .menu-list:before, .footer .sitemap > .menu-list:after {
      content: "";
      display: table; }
    .footer .sitemap > .menu-list:after {
      clear: both; }
    .footer .sitemap > .menu-list:last-child {
      float: left;
      width: 33.33333%; }
  .footer .sitemap > .menu-list:not(:last-child) > .menu-item {
    float: left;
    width: 50%; }
  .footer .logo {
    width: 17.5em; }
  /* ------------------------------------------------------------------------ * * Module                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Hiders                                                                   *
\* ------------------------------------------------------------------------ */
  .hide-xl {
    display: none !important; }
    .hide-xl.inline {
      display: none !important; }
  .show-xl {
    display: block !important; }
    .show-xl.inline {
      display: inline-block !important; }
  /* ------------------------------------------------------------------------ * * State                                                                    *
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 80rem) {
  /* ------------------------------------------------------------------------ * * Base                                                                     *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Grid                                                                     *
\* ------------------------------------------------------------------------ */
  .grid-item.full-xxl {
    width: 100%; }
  .grid-item.one-half-xxl {
    width: 50%; }
  .grid-item.one-third-xxl {
    width: 33.33333%; }
  .grid-item.one-fourth-xxl {
    width: 25%; }
  .grid-item.one-fifth-xxl {
    width: 20%; }
  .grid-item.one-sixth-xxl {
    width: 16.66667%; }
  .grid-item.one-seventh-xxl {
    width: 14.28571%; }
  .grid-item.one-eighth-xxl {
    width: 12.5%; }
  .grid-item.one-ninth-xxl {
    width: 11.11111%; }
  .grid-item.one-tenth-xxl {
    width: 10%; }
  .grid-item.two-half-xxl,
  .grid-item.two-halfs-xxl,
  .grid-item.two-halves-xxl {
    width: 66.66667%; }
  .grid-item.two-third-xxl,
  .grid-item.two-thirds-xxl {
    width: 66.66667%; }
  .grid-item.two-fourth-xxl,
  .grid-item.two-fourths-xxl {
    width: 50%; }
  .grid-item.two-fifth-xxl,
  .grid-item.two-fifths-xxl {
    width: 40%; }
  .grid-item.two-sixth-xxl,
  .grid-item.two-sixths-xxl {
    width: 33.33333%; }
  .grid-item.two-seventh-xxl,
  .grid-item.two-sevenths-xxl {
    width: 28.57143%; }
  .grid-item.two-eighth-xxl,
  .grid-item.two-eighths-xxl {
    width: 25%; }
  .grid-item.two-ninth-xxl,
  .grid-item.two-ninths-xxl {
    width: 22.22222%; }
  .grid-item.two-tenth-xxl,
  .grid-item.two-tenths-xxl {
    width: 20%; }
  .grid-item.three-third-xxl,
  .grid-item.three-thirds-xxl {
    width: 100%; }
  .grid-item.three-fourth-xxl,
  .grid-item.three-fourths-xxl {
    width: 75%; }
  .grid-item.three-fifth-xxl,
  .grid-item.three-fifths-xxl {
    width: 60%; }
  .grid-item.three-sixth-xxl,
  .grid-item.three-sixths-xxl {
    width: 50%; }
  .grid-item.three-seventh-xxl,
  .grid-item.three-sevenths-xxl {
    width: 42.85714%; }
  .grid-item.three-eighth-xxl,
  .grid-item.three-eighths-xxl {
    width: 37.5%; }
  .grid-item.three-ninth-xxl,
  .grid-item.three-ninths-xxl {
    width: 33.33333%; }
  .grid-item.three-tenth-xxl,
  .grid-item.three-tenths-xxl {
    width: 30%; }
  .grid-item.four-fourth-xxl,
  .grid-item.four-fourths-xxl {
    width: 100%; }
  .grid-item.four-fifth-xxl,
  .grid-item.four-fifths-xxl {
    width: 80%; }
  .grid-item.four-sixth-xxl,
  .grid-item.four-sixths-xxl {
    width: 66.66667%; }
  .grid-item.four-seventh-xxl,
  .grid-item.four-sevenths-xxl {
    width: 57.14286%; }
  .grid-item.four-eighth-xxl,
  .grid-item.four-eighths-xxl {
    width: 50%; }
  .grid-item.four-ninth-xxl,
  .grid-item.four-ninths-xxl {
    width: 44.44444%; }
  .grid-item.four-tenth-xxl,
  .grid-item.four-tenths-xxl {
    width: 40%; }
  .grid-item.five-fifth-xxl,
  .grid-item.five-fifths-xxl {
    width: 100%; }
  .grid-item.five-sixth-xxl,
  .grid-item.five-sixths-xxl {
    width: 83.33333%; }
  .grid-item.five-seventh-xxl,
  .grid-item.five-sevenths-xxl {
    width: 71.42857%; }
  .grid-item.five-eighth-xxl,
  .grid-item.five-eighths-xxl {
    width: 62.5%; }
  .grid-item.five-ninth-xxl,
  .grid-item.five-ninths-xxl {
    width: 55.55556%; }
  .grid-item.five-tenth-xxl,
  .grid-item.five-tenths-xxl {
    width: 50%; }
  .grid-item.six-sixth-xxl,
  .grid-item.six-sixths-xxl {
    width: 100%; }
  .grid-item.six-seventh-xxl,
  .grid-item.six-sevenths-xxl {
    width: 85.71429%; }
  .grid-item.six-eighth-xxl,
  .grid-item.six-eighths-xxl {
    width: 75%; }
  .grid-item.six-ninth-xxl,
  .grid-item.six-ninths-xxl {
    width: 66.66667%; }
  .grid-item.six-tenth-xxl,
  .grid-item.six-tenths-xxl {
    width: 60%; }
  .grid-item.seven-seventh-xxl,
  .grid-item.seven-sevenths-xxl {
    width: 100%; }
  .grid-item.seven-eighth-xxl,
  .grid-item.seven-eighths-xxl {
    width: 87.5%; }
  .grid-item.seven-ninth-xxl,
  .grid-item.seven-ninths-xxl {
    width: 77.77778%; }
  .grid-item.seven-tenth-xxl,
  .grid-item.seven-tenths-xxl {
    width: 70%; }
  .grid-item.eight-eighth-xxl,
  .grid-item.eight-eighths-xxl {
    width: 100%; }
  .grid-item.eight-ninth-xxl,
  .grid-item.eight-ninths-xxl {
    width: 88.88889%; }
  .grid-item.eight-tenth-xxl,
  .grid-item.eight-tenths-xxl {
    width: 80%; }
  .grid-item.nine-ninth-xxl,
  .grid-item.nine-ninths-xxl {
    width: 100%; }
  .grid-item.nine-tenth-xxl,
  .grid-item.nine-tenths-xxl {
    width: 90%; }
  .grid-item.ten-tenth-xxl,
  .grid-item.ten-tenths-xxl {
    width: 100%; }
  /* ------------------------------------------------------------------------ * * Layout                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Header                                                                   *
\* ------------------------------------------------------------------------ */
  .header-wrapper {
    position: relative;
    width: 100%; }
  .header .header-left:after {
    border-bottom: 6.625em solid transparent;
    border-right: 6.625em solid #000000;
    border-top: 6.625em solid transparent;
    margin-top: -6.625em; }
  .header .header-right {
    width: 38.75em; }
    .modernizr-no-flexbox.modernizr-no-flexboxlegacy .header .header-right {
      height: 13.4375em; }
  .header .logo {
    padding: 1.53125em 0em;
    width: 21.875em; }
  .header .hdg.hdg4 {
    margin: 0.2em 1.2em 0.15em 0em; }
  .header .search-form {
    margin: 0em 0.125em 0em 0.4375em; }
  .header .menu-wrapper.social {
    padding: 2em 0em 1em 1.75em; }
  .header .menu-wrapper.secondary {
    display: flex;
    padding: 0em 0em 0em 0.8125em; }
  .header .menu-wrapper.primary {
    display: flex;
    padding: 0; }
  .header .menu-wrapper.social .menu-item:not(:last-child) {
    margin: 0em 0.375em 0em 0em; }
  .header .menu-wrapper.secondary .menu-item a {
    padding: 0.875em 0.9375em 0.625em; }
  .header .menu-wrapper.primary .menu-item a {
    padding: 0.925em 1.4em; }
  .header .menu-wrapper.primary .menu-item:last-child a {
    padding-right: 0; }
  /* ------------------------------------------------------------------------ * * Slideshow                                                                *
\* ------------------------------------------------------------------------ */
  .slideshow-wrapper {
    padding-bottom: 50px;
    padding-bottom: 3.125rem;
    padding-top: 50px;
    padding-top: 3.125rem; }
  .slideshow-wrapper .slideshow-menu .menu-list {
    font-size: 1.28571em; }
  .slideshow-wrapper .slideshow-menu .menu-item .thumbnail-text {
    background-size: 4em auto;
    padding-left: 5em; }
  /* ------------------------------------------------------------------------ * * Module                                                                   *
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Hiders                                                                   *
\* ------------------------------------------------------------------------ */
  .hide-xxl {
    display: none !important; }
    .hide-xxl.inline {
      display: none !important; }
  .show-xxl {
    display: block !important; }
    .show-xxl.inline {
      display: inline-block !important; }
  /* ------------------------------------------------------------------------ * * State                                                                    *
\* ------------------------------------------------------------------------ */ }
