// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Magic 360                                                                *
\* ------------------------------------------------------------------------ */

.Magic360-container {
    & {
        border: 0 !important;
        margin: 0 auto !important;
    }
}
