// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Grid                                                                     *
\* ------------------------------------------------------------------------ */

// full

.grid-item.full-xl {
    width: 100%;
}

// ones

.grid-item.one-half-xl {
    width: (100% / 2);
}

.grid-item.one-third-xl {
    width: (100% / 3);
}

.grid-item.one-fourth-xl {
    width: (100% / 4);
}

.grid-item.one-fifth-xl {
    width: (100% / 5);
}

.grid-item.one-sixth-xl {
    width: (100% / 6);
}

.grid-item.one-seventh-xl {
    width: (100% / 7);
}

.grid-item.one-eighth-xl {
    width: (100% / 8);
}

.grid-item.one-ninth-xl {
    width: (100% / 9);
}

.grid-item.one-tenth-xl {
    width: (100% / 10);
}

// twos

.grid-item.two-half-xl,
.grid-item.two-halfs-xl,
.grid-item.two-halves-xl {
    width: (100% / 3) * 2;
}

.grid-item.two-third-xl,
.grid-item.two-thirds-xl {
    width: (100% / 3) * 2;
}

.grid-item.two-fourth-xl,
.grid-item.two-fourths-xl {
    width: (100% / 4) * 2;
}

.grid-item.two-fifth-xl,
.grid-item.two-fifths-xl {
    width: (100% / 5) * 2;
}

.grid-item.two-sixth-xl,
.grid-item.two-sixths-xl {
    width: (100% / 6) * 2;
}

.grid-item.two-seventh-xl,
.grid-item.two-sevenths-xl {
    width: (100% / 7) * 2;
}

.grid-item.two-eighth-xl,
.grid-item.two-eighths-xl {
    width: (100% / 8) * 2;
}

.grid-item.two-ninth-xl,
.grid-item.two-ninths-xl {
    width: (100% / 9) * 2;
}

.grid-item.two-tenth-xl,
.grid-item.two-tenths-xl {
    width: (100% / 10) * 2;
}

// threes

.grid-item.three-third-xl,
.grid-item.three-thirds-xl {
    width: (100% / 3) * 3;
}

.grid-item.three-fourth-xl,
.grid-item.three-fourths-xl {
    width: (100% / 4) * 3;
}

.grid-item.three-fifth-xl,
.grid-item.three-fifths-xl {
    width: (100% / 5) * 3;
}

.grid-item.three-sixth-xl,
.grid-item.three-sixths-xl {
    width: (100% / 6) * 3;
}

.grid-item.three-seventh-xl,
.grid-item.three-sevenths-xl {
    width: (100% / 7) * 3;
}

.grid-item.three-eighth-xl,
.grid-item.three-eighths-xl {
    width: (100% / 8) * 3;
}

.grid-item.three-ninth-xl,
.grid-item.three-ninths-xl {
    width: (100% / 9) * 3;
}

.grid-item.three-tenth-xl,
.grid-item.three-tenths-xl {
    width: (100% / 10) * 3;
}

// fours

.grid-item.four-fourth-xl,
.grid-item.four-fourths-xl {
    width: (100% / 4) * 4;
}

.grid-item.four-fifth-xl,
.grid-item.four-fifths-xl {
    width: (100% / 5) * 4;
}

.grid-item.four-sixth-xl,
.grid-item.four-sixths-xl {
    width: (100% / 6) * 4;
}

.grid-item.four-seventh-xl,
.grid-item.four-sevenths-xl {
    width: (100% / 7) * 4;
}

.grid-item.four-eighth-xl,
.grid-item.four-eighths-xl {
    width: (100% / 8) * 4;
}

.grid-item.four-ninth-xl,
.grid-item.four-ninths-xl {
    width: (100% / 9) * 4;
}

.grid-item.four-tenth-xl,
.grid-item.four-tenths-xl {
    width: (100% / 10) * 4;
}

// fives

.grid-item.five-fifth-xl,
.grid-item.five-fifths-xl {
    width: (100% / 5) * 5;
}

.grid-item.five-sixth-xl,
.grid-item.five-sixths-xl {
    width: (100% / 6) * 5;
}

.grid-item.five-seventh-xl,
.grid-item.five-sevenths-xl {
    width: (100% / 7) * 5;
}

.grid-item.five-eighth-xl,
.grid-item.five-eighths-xl {
    width: (100% / 8) * 5;
}

.grid-item.five-ninth-xl,
.grid-item.five-ninths-xl {
    width: (100% / 9) * 5;
}

.grid-item.five-tenth-xl,
.grid-item.five-tenths-xl {
    width: (100% / 10) * 5;
}

// sixes

.grid-item.six-sixth-xl,
.grid-item.six-sixths-xl {
    width: (100% / 6) * 6;
}

.grid-item.six-seventh-xl,
.grid-item.six-sevenths-xl {
    width: (100% / 7) * 6;
}

.grid-item.six-eighth-xl,
.grid-item.six-eighths-xl {
    width: (100% / 8) * 6;
}

.grid-item.six-ninth-xl,
.grid-item.six-ninths-xl {
    width: (100% / 9) * 6;
}

.grid-item.six-tenth-xl,
.grid-item.six-tenths-xl {
    width: (100% / 10) * 6;
}

// sevens

.grid-item.seven-seventh-xl,
.grid-item.seven-sevenths-xl {
    width: (100% / 7) * 7;
}

.grid-item.seven-eighth-xl,
.grid-item.seven-eighths-xl {
    width: (100% / 8) * 7;
}

.grid-item.seven-ninth-xl,
.grid-item.seven-ninths-xl {
    width: (100% / 9) * 7;
}

.grid-item.seven-tenth-xl,
.grid-item.seven-tenths-xl {
    width: (100% / 10) * 7;
}

// eights

.grid-item.eight-eighth-xl,
.grid-item.eight-eighths-xl {
    width: (100% / 8) * 8;
}

.grid-item.eight-ninth-xl,
.grid-item.eight-ninths-xl {
    width: (100% / 9) * 8;
}

.grid-item.eight-tenth-xl,
.grid-item.eight-tenths-xl {
    width: (100% / 10) * 8;
}

// nines

.grid-item.nine-ninth-xl,
.grid-item.nine-ninths-xl {
    width: (100% / 9) * 9;
}

.grid-item.nine-tenth-xl,
.grid-item.nine-tenths-xl {
    width: (100% / 10) * 9;
}

// tens

.grid-item.ten-tenth-xl,
.grid-item.ten-tenths-xl {
    width: (100% / 10) * 10;
}
