// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Login Form                                                            *
\* ------------------------------------------------------------------------ */

.login-form {
    border: 1px solid transparentize((lighten($foreground, 54.9020)), 0.65);
    display: block;
    font-size: 16px;
    font-size: remify(16);
    margin: 0 auto;
    max-width: remify(320, 16);
    padding: remify(20, 16);
}

.login-input-wrapper {
    margin: remify(0 0 10, 16);
    position: relative;
}

.login-button,
.login-input,
.login-label,
.login-notice {
    display: block;
    font-family: $body-font;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    width: 100%;
}

.login-button {
    background: $accent;
    border: 0;
    color: $background;
    font-size: remify(16, 16);
    font-weight: 700;
    padding: remify(8 12 7, 16);
    text-align: left;
    text-transform: uppercase;
	transition: background 0.15s, color 0.15s;

    &:hover {
        background: $accent_alt;
        color: $background;
    }
}

.login-input {
    border: 1px solid (lighten($foreground, 54.9020));
    border-radius: remify(4, 16);
    font-size: remify(16, 16);
    padding: remify(8 12 7, 16);
}

.login-label {
    font-size: remify(12, 16);
    text-transform: uppercase;
}

.login-notice {
    font-size: remify(12, 16);
    text-align: right;
}

.login-notice a {
    color: $foreground;
    text-decoration: none;
    transition: color 0.15s;

    &:hover {
        color: $accent;
    }
}
