// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Text Elements                                                            *
\* ------------------------------------------------------------------------ */

::selection {
	background: $accent_alt;
	color: $background;
}

.hdg {
    color: $accent;
    font-family: $heading-font;
    font-weight: 700;
    text-transform: uppercase;
}

.hdg.hdg1,
.hdg.hdg2 {
    font-size: 15px;
    font-size: remify(15);
    margin: remify(0 0 4, 15);
}

.hdg.hdg3,
.hdg.hdg4 {
    font-size: 14px;
    font-size: remify(14);
    margin: remify(0, 14);
}

.hdg.hdg5 {
    font-size: 13px;
    font-size: remify(13);
    margin: remify(0, 13);
}

.hdg.hdg6 {
    color: $foreground;
    font-family: $heading-font;
    font-size: 13px;
    font-size: remify(13);
    font-weight: 400;
    margin: remify(0, 13);
}

.txt {
    color: $foreground;
    font-family: $body-font;
    font-size: 12px;
    font-size: remify(12);
    line-height: remify(15, 12);
    margin: remify(0 0 28, 12);
}

.link {
    color: $accent;
    text-decoration: none;
    transition: color 0.15s;

    &:hover {
        color: $accent_alt;
    } 
}
