// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * wp-caption                                                               *
\* ------------------------------------------------------------------------ */

.wp-caption {
    background: $primary;
    margin: remify(0 0 10, 16);
	margin: 0 auto 10px;
    padding: remify(6, 16);
	width: remify(300, 16);
}

.wp-caption.full {
	width: auto;
}

.wp-caption.no-back {
	background: none;
}

.wp-caption img {
    display: block;
	margin: 0 auto;
}

.wp-caption-text {
    color: $background !important;
	font-style: italic;
    margin: 0;
    padding: remify(6 20 3, 20);
	text-align: center
}

.wp-caption.no-back .wp-caption-text {
	color: $foreground !important;
}

.wp-caption-text a {
    color: $background;
    display: block;
}
