// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Header                                                                   *
\* ------------------------------------------------------------------------ */

.header-wrapper {
    @include layer("header");

    background: $page_background;
    font-size: 16px;
    font-size: remify(16);
}

.header {
    @include clear;

    display: flex;
    margin: 0 auto;
    max-width: $site-width + px;
    max-width: remify($site-width);
}

.header .header-left {
    @include flex-fix;

    background: $page_background_alt;
    flex: 1 1 auto;
    float: left;
    position: relative;

    &:after {
        border-bottom: remify(37.5, 16) solid transparent;
        border-right: remify(37.5, 16) solid $page_background;
        border-top: remify(37.5, 16) solid transparent;
        content: "\0020";
        display: block;
        height: 0;
        margin-top: - remify(37.5, 16);
        position: absolute;
        right: 0;
        top: 50%;
        width: 0;
    }
}

.header .header-right {
    @include flex-fix;

    flex: none;
    float: right;
    position: relative;
    width: remify(75, 16);
}

.header .logo {
    padding: remify(10 24, 16);
    width: remify(168, 16);
}

.header .menu-button {
    margin: remify(17 0 17 15, 16);
}
