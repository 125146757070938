// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Slideshow                                                                *
\* ------------------------------------------------------------------------ */

.slideshow-wrapper .slideshow-menu .menu-item {
    float: left;
    width: 50%;

    &:nth-child(1),
    &:nth-child(3) {
        padding-right: 1px;
    }
}
