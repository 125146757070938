// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Mini Article                                                             *
\* ------------------------------------------------------------------------ */

.mini-article:not(:last-child) {
    margin: remify(0 0 20, 16);
}

.mini-article a {
    color: $accent;
    text-decoration: none;
    transition: color 0.15s;
    
    &:hover {
        color: $accent_alt;
    }
}
